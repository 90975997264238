import React from 'react'
import Nav from '../Nav'
import Content from './Content'
import { CurrentProductProvider } from '../../../entities/Guides'
import { Products } from '../../../entities/Guides'

type ListProps = {
  products: Products
}

export const List = (props: ListProps) => {
  const { products } = props

  return (
    <CurrentProductProvider>
      <Nav products={products} />
      <Content products={products} />
    </CurrentProductProvider>
  )
}

export default List
