import React from 'react'
import Hero from './Hero'
import Info from './Info'
import Cards from './Cards'
import CodeBlock from './CodeBlock'
import TechnologyHome from './TechnologyHome'
import GetStarted from './GetStarted'

const Home = () => {
  return (
    <React.Fragment>
      <Hero />
      <Info />
      <Cards />
      <CodeBlock />
      <TechnologyHome />
      <GetStarted />
    </React.Fragment>
  )
}

export default Home
