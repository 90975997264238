import styled, { css } from 'styled-components'
import { Flex, getPaletteColor, Card as pclnCard } from 'pcln-design-system'
import { NavLink as routeNavLink } from 'react-router-dom'
import LoadingIcon from '../../../components/LoadingIcon'

interface ContainerProps {
  isActive: boolean
}

export const Card = styled(pclnCard)`
  background: ${getPaletteColor('background.lightest')};
  color: ${getPaletteColor('text.base')};
`

export const NavContainer = styled(Flex)`
  background: ${getPaletteColor('primary.base')};
  color: ${getPaletteColor('text.lightest')};
  height: 40px;
`

export const NavItems = styled(Flex)`
  width: 100%;
`

export const NavLink = styled(routeNavLink)`
  color: ${(props) => getPaletteColor('text.lightest')(props)};
  text-transform: capitalize;
  text-decoration: none;
  height: 100%;
`

export const Name = styled.span`
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
`

const active = css`
  font-weight: bolder;
`

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  height: 100%;
  ${({ isActive }) => (isActive ? `& ${Name} { ${active} }` : '')}

  &:hover ${Name} {
    ${active}
  }
`

export const Icon = styled(LoadingIcon)`
  height: 25px;
  width: 25px;
  background: ${getPaletteColor('background.lightest')};
  border-radius: 3px;
`

export const ProductsContainer = styled(Flex)`
  max-width: 1100px;
  height: 100%;
`

interface VersionProps {
  isActive: boolean
}

export const Version = styled.div<VersionProps>`
  padding: 15px;
`

export const VersionSelectorContainer = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 10px;
  right: 0;
  z-index: 3;
`

export const VersionsContainer = styled(Flex)`
  height: 100%;
  min-width: 150px;
  padding: 0 15px;
  position: relative;
  cursor: pointer;
`

export const VersionLabel = styled.label`
  text-transform: uppercase;
  font-size: 12px;
  margin-right: 10px;
`

export const CurrentVersion = styled.div`
  font-weight: bold;
`
