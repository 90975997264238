import React from 'react'
import styled from 'styled-components'
import { Card as pclnCard, Heading, Flex } from 'pcln-design-system'
import Link from '../../components/Link'
import media from '../../media'

const Container = styled(Flex)`
  width: 100%;
  padding: 20px;
  width: 33%;

  ${media.tablet`
        width: 100%;`}
`

const CardBox = styled(pclnCard)`
  padding: 10px 20px;
  background: white;
  height: 100%;
`

const Info = styled(Flex)`
  height: 100%;
  text-align: center;
  padding: 40px 0;
  flex-direction: column;
  align-content: stretch;
`

const Image = styled.img`
  margin: 0 auto;
  width: 120px;
  height: 120px;
`

const Title = styled(Heading.h3)`
  margin: 10px;
  font-size: 16px;
`

const Description = styled(Heading)`
  padding: 5px 10px;
  margin: 0;
  font-size: 12px;
  line-height: 1.8;
  flex: 1;
`

const LinkContainer = styled(Flex)`
  align-content: flex-end;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const LinkButton = styled(Link)`
  color: ${({ theme }) => theme.primary};
  font-size: 12px;
  margin-top: 0.5em;
`

const Card = ({
  icon,
  title,
  description,
  link,
  href,
  link2,
  href2,
  link3,
  href3,
  ...props
}) => {
  return (
    <Container>
      <CardBox
        {...props}
        width={'100%'}
        boxShadowSize={'xl'}
        borderWidth={0}
        borderRadius={10}
      >
        <Info>
          <Image src={icon} />
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
          <LinkContainer>
            {link && <LinkButton href={href}>{link}</LinkButton>}
            {link2 && <LinkButton href={href2}>{link2}</LinkButton>}
            {link3 && <LinkButton href={href3}>{link3}</LinkButton>}
          </LinkContainer>
        </Info>
      </CardBox>
    </Container>
  )
}

Card.defaultProps = {
  title: '',
  description: '',
  link: '',
  href: '',
  link2: '',
  href2: '',
  link3: '',
  href3: '',
}

export default Card
