import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 20px;
  background: #031832;
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
  text-align: initial;
  overflow-x: auto;
  margin-bottom: 20px;
`
