import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import IconNav from '../../components/IconNav'
import {
  Product,
  Products,
  useCurrentProductContext,
} from '../../entities/Guides'

type NavProps = {
  products: Products
}

const Nav = (props: NavProps) => {
  const { products } = props

  const { currentProduct } = useCurrentProductContext()
  const { vertical } = useParams()

  const navItems = useMemo(
    () =>
      Object.values(products).map((product: Product) => ({
        isActive: product.name === currentProduct?.name,
        to: `/${vertical}/guides/${product.name}`,
        title: product.title,
        icon: product.icon,
      })),
    [products, currentProduct, vertical]
  )

  return <IconNav title={'Guides'} navItems={navItems} />
}

export default Nav
