import { useParams } from 'react-router-dom'

const HOTEL = 'hotel'

// TODO - Clean up or remove versions PPN-66752
export const useVersion = (): string => {
  const { product } = useParams()
  return mapProduct(product) === HOTEL ? 'unified_express_path' : '1.0.0'
}

export const mapProduct = (product: string | undefined): string => {
  if (!product) return HOTEL
  return product
}
