import { ReactNode, useMemo, useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ProductItem from './Product'
import {
  NavContainer,
  NavItems,
  ProductsContainer,
  VersionsContainer,
} from './styled'
import Versions from './Versions'
import useGuidesAPI from '../../../api/API'
import { apiVertical } from '../../../entities/Verticals/constants'

const Nav = () => {
  const { product } = useParams()
  const api = useGuidesAPI()
  const [products, setProducts] = useState([] as string[])

  const APIRef = useRef(api)

  // TODO - PPN-66526 Replace with useCallback
  useEffect(() => {
    APIRef.current = api
  }, [api])

  useEffect(() => {
    const fetchProducts = async () => {
      const { data } = await APIRef.current.fetchDocumentationProducts(
        apiVertical
      )
      setProducts(data.products as any)
    }

    fetchProducts()
  }, [APIRef])

  const renderedProducts = useMemo(
    () =>
      Object.values((products as unknown) as string[]).reduce(
        (renderedProducts: ReactNode[], navProduct: string) => {
          return [
            ...renderedProducts,
            <ProductItem
              key={navProduct}
              name={navProduct.toLowerCase()}
              title={mapDisplayName(navProduct)}
              isActive={navProduct.toLowerCase() === product}
            />,
          ]
        },
        [] as ReactNode[]
      ),
    [product, products]
  )

  return (
    <NavContainer justifyContent={'center'}>
      <NavItems alignItems={'center'} justifyContent={'space-between'}>
        <ProductsContainer>{renderedProducts}</ProductsContainer>
        <VersionsContainer>
          <Versions />
        </VersionsContainer>
      </NavItems>
    </NavContainer>
  )
}
// TODO PPN-66753 - Currently this is displayed as "Flight" to the user only
// but the URL/API has "Air"
const mapDisplayName = (product: string): string => {
  return product === 'Air' ? 'Flight' : product
}

export default Nav
