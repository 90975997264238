import styled from 'styled-components'
import { Flex, getPaletteColor, Heading } from 'pcln-design-system'
import media from '../../media'

// @ts-ignore
const tablet = media.tablet

export const Container = styled(Flex)`
  min-height: 100vh;
  padding: 25px 0;

  ${tablet`
        flex-direction: column;`}
`

export const CategoryContainer = styled.div`
  margin-bottom: 30px;
`

export const CategoryInfo = styled.div`
  margin-bottom: 20px;
`

export const CategoryTitle = styled(Heading.h3)`
  margin: 0;
`

export const CategoryDescription = styled(Heading.h4)`
  margin: 0;
  font-weight: 200;
  color: ${getPaletteColor('text.light')};
  text-decoration: none;
`

export const CategoriesContainer = styled.div``
