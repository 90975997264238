import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { getPaletteColor } from 'pcln-design-system'

export const Container = styled.div``

export const Link = styled(RouterLink)`
  display: inline-block;
  padding: 15px 40px;
  color: ${getPaletteColor('text.lightest')};
  background: ${getPaletteColor('primary.base')};
  border: 2px solid ${getPaletteColor('background.lightest')};
  transition: background 0.2s;
  text-decoration: none;

  &:hover {
    color: ${getPaletteColor('primary.base')};
    background: ${getPaletteColor('background.lightest')};
  }
`
