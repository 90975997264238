import { useEffect, useState, useRef } from 'react'
import {
  CategoriesContainer,
  CategoryContainer,
  CategoryInfo,
  CategoryTitle,
  CategoryDescription,
} from '../styled'
import { GuideCardLink } from '../../../components/GuideCard'
import { Guide, Guides } from '../../../entities/Guides/types'
import { Category as iCategory } from '../../../entities/Guides'
import { mapVertical } from '../../../entities/Verticals/mappers'
import useGuidesAPI from '../../../api/API'

const Category = (props: iCategory) => {
  const { guides, title, description, name } = props

  const renderedGuides = Object.values(guides).reduce(
    (renderedGuides: JSX.Element[], currentGuide: Guide) => {
      return currentGuide.categories.find(
        (categoryName) => categoryName === name
      )
        ? [
            ...renderedGuides,
            <GuideCardLink key={currentGuide.name} {...currentGuide} />,
          ]
        : renderedGuides
    },
    []
  )

  return (
    <CategoryContainer>
      <CategoryInfo>
        <CategoryTitle>{title}</CategoryTitle>
        <CategoryDescription>{description}</CategoryDescription>
        {renderedGuides}
      </CategoryInfo>
    </CategoryContainer>
  )
}

type CategoriesProps = {
  guides: Guides
  product: string | undefined
  defaultProduct: string
  vertical: string | undefined
}

const Categories = (props: CategoriesProps) => {
  const { defaultProduct, product, guides, vertical } = props
  const [categories, setCategories] = useState({} as iCategory[])

  const api = useGuidesAPI()
  const APIRef = useRef(api)

  useEffect(() => {
    const fetchCategories = async (product: string, vertical: string) => {
      const { data } = await APIRef.current.fetchCategories(
        product,
        mapVertical(vertical)
      )
      setCategories(data as iCategory[])
    }
    if (product) {
      fetchCategories(product, vertical || '')
    } else {
      fetchCategories(defaultProduct, vertical || '')
    }
  }, [defaultProduct, product, vertical])

  const renderedCategories = Object.values(categories).map((category) => (
    <Category key={category.name} {...category} guides={guides} />
  ))

  return <CategoriesContainer>{renderedCategories}</CategoriesContainer>
}

export default Categories
