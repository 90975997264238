import {
  HeadingsActions,
  HeadingsState,
  UseHeadingsState,
} from './useHeadingsState'

export interface UseHeadings extends HeadingsState, HeadingsActions {}

export interface UseHeadingsProps {
  state: UseHeadingsState
}

const useHeadings = (props: UseHeadingsProps): UseHeadings => {
  const {
    state: { actions, state },
  } = props

  return {
    ...state,
    ...actions,
  }
}

export default useHeadings
