import React from 'react'
import { Route as ReactRouterRoute } from 'react-router-dom'
import { Routes, Route } from './types'

export const useRenderedRoutes = (routes: Routes) =>
  Object.values(routes).map((route: Route) => (
    <ReactRouterRoute
      key={route.name}
      path={route.path}
      element={route.component}
    />
  ))
