import React from 'react'
import { useAppContext } from '../../entities/App/AppProvider'
import { PPNFooter } from '@pcln/chronos-react-components'

const Footer = () => {
  const { config } = useAppContext()

  if (!config) return <></>

  return <PPNFooter ppncomURL={config.PPNCOM_URL} />
}

export default Footer
