import styled from 'styled-components'
import { Link as routerLink } from 'react-router-dom'
import { Card, Heading, getPaletteColor } from 'pcln-design-system'

export const Link = styled(routerLink)`
  text-decoration: none;
  margin-bottom: 50px;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
export const Container = styled(Card)`
  background: ${getPaletteColor('background.lightest')};
  padding: 10px 20px;
  margin-bottom: 20px;
`

export const Title = styled(Heading.h3)`
  font-weight: bolder;
  font-size: 25px;
  margin: 0;
  padding: 5px;
  border-bottom: 1px ${getPaletteColor('border.base')} solid;
`

export const SubTitle = styled(Heading.h4)`
  margin: 0;
  font-weight: 200;
  font-size: 15px;
  padding: 10px 5px;
  color: ${getPaletteColor('text.light')};
`
