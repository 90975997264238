import React from 'react'
import styled from 'styled-components'
import {
  BackgroundImage,
  Heading,
  Flex,
  getPaletteColor,
} from 'pcln-design-system'
import Section from './Section'
import PlatformBG from '../../assets/images/platform.png'
import media from '../../media'

const Container = styled(Section)``

const Background = styled(BackgroundImage)`
  background: url(${PlatformBG}) no-repeat 50%;

  ${media.tablet2`
        background-image: none;`}
`

const FlexWrapper = styled(Flex)`
  margin: 0px auto;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  padding: 20px;
  justify-content: center;
`

const Content = styled.div`
  margin: 40px 0px;
  font-weight: 300;
  font-size: 22px;
`

const Paragraph = styled.p`
  margin: 20px 0;
  width: 50%;
  font-size: 17px;

  ${media.tablet2`
        width: 100%;`}
`

const BlueSpan = styled.span`
  color: ${getPaletteColor('primary.base')};
`

const Info = () => {
  return (
    <Container>
      <Background height={'100%'}>
        <FlexWrapper>
          <Content>
            <Heading.h4 m={0}>{`PRICELINE PARTNER SOLUTION'S`}</Heading.h4>
            <Heading.h2 m={0}>
              <BlueSpan>{'API'}</BlueSpan> {'Direct Platform'}
            </Heading.h2>
            <Paragraph>
              {
                'The Priceline Partner Solutions API makes connecting to multiple product sources for rates and availability directly on your website easier than ever before. We respond instantly with dynamic Hotel, Rental Car, and Flight content, and support many use cases customized to your business needs.'
              }
            </Paragraph>
            <Paragraph>
              {
                'Using our API makes it possible to integrate our Hotel, Rental Car, and Flight products and their various functions directly onto your website, mobile app or application; giving your customers access to the best travel deals in the world.'
              }
            </Paragraph>
          </Content>
        </FlexWrapper>
      </Background>
    </Container>
  )
}

export default Info
