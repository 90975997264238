import styled from 'styled-components'
import { Flex } from 'pcln-design-system'
import media from '../../../media'

// @ts-ignore
const tablet = media.tablet

export const Container = styled(Flex)`
  width: 50%;
  position: relative;

  ${tablet`
        flex: 1;
        width: initial;
    `}
`

interface ContentContainerProps {
  $loading: boolean
}

export const ContentContainer = styled.div<ContentContainerProps>`
  flex: 1;
  margin-bottom: 40px;
  padding: 0 40px;
  max-width: 100%;
  height: 100%;
  transition: all 0.1s;
  overflow-wrap: break-word;
  overflow-y: scroll;
  ${({ $loading }) => ($loading ? `filter: blur(5px);` : '')}

  ${tablet`
        width: 100%;
        overflow: initial;
        padding: 40px;`}
`
