import { Route, Routes, Navigate, useParams } from 'react-router-dom'

const RedirectGuide = () => {
  const { name } = useParams()

  return <Navigate to={`/pps-api/guides/guide/${name}`} />
}

const RedirectProduct = () => {
  const { product } = useParams()
  return <Navigate to={`/pps-api/guides/${product}`} />
}

const LegacyGuideRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={`/pps-api/guides`} />} />
      <Route path="/id/:name" element={<RedirectGuide />} />
      <Route path="/:product?" element={<RedirectProduct />} />
    </Routes>
  )
}
export default LegacyGuideRoutes
