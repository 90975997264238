import { useEffect, useState, useRef } from 'react'
import { Route, Routes, Outlet, useParams } from 'react-router-dom'
import { mapVertical } from '../../entities/Verticals/mappers'
import useGuidesAPI from '../../api'
import Guide from '../Guide/Guide'
import { List } from './List'
import { Container } from './styled'

const Layout = () => (
  <Container>
    <Outlet />
  </Container>
)

const Guides = () => {
  const api = useGuidesAPI()
  const { vertical } = useParams()
  const [products, setProducts] = useState({})
  const APIRef = useRef(api)

  // TODO - PPN-66526 Replace with useCallback
  useEffect(() => {
    APIRef.current = api
  }, [api])

  useEffect(() => {
    const fetchProducts = async (vertical: string) => {
      const { data } = await APIRef.current.fetchGuidesProducts(vertical)
      setProducts(data.products as any)
    }

    if (vertical) fetchProducts(mapVertical(vertical))
  }, [APIRef, vertical])

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={`/guide/:name`} element={<Guide products={products} />} />
        <Route path={`/:product?`} element={<List products={products} />} />
      </Route>
    </Routes>
  )
}

export default Guides
