import React, { Suspense } from 'react'
import { useAppContext } from './entities/App/AppProvider'
import { useRenderedRoutes } from './entities/Routes'
import { Route, Routes } from 'react-router-dom'
import routes from './routes'
import Nav from './components/Nav'
import Footer from './components/Footer'
import { useAuthContext } from './entities/Auth'

const PPNDocs = () => {
  const renderedRoutes = useRenderedRoutes(routes)
  const { initialized } = useAppContext()

  const WithContainer = () => (
    <>
      <Nav />
      <Routes>{renderedRoutes}</Routes>
      <Footer />
    </>
  )

  return (
    <React.Fragment>
      {initialized ? (
        <Suspense fallback={null}>
          <Routes>
            <Route path="/login/*" element={<Login />}></Route>
            <Route path="*" element={<WithContainer />} />
          </Routes>
        </Suspense>
      ) : null}
      <div id={'modals'} />
    </React.Fragment>
  )
}

const Login = () => {
  const { login } = useAuthContext()
  // This is a hack to stop Okta from refreshing the access token at the same time we log in
  // TODO find a better fix this https://priceline.atlassian.net/browse/PPN-75191
  localStorage.removeItem('ppn-docs')

  login()

  return <></>
}

export default PPNDocs
