import React from 'react'
import styled from 'styled-components'
import { BackgroundImage, Heading, Flex } from 'pcln-design-system'
import Section from './Section'
import Computer from '../../assets/images/computer.png'
import BlueBg from '../../assets/images/blue_bg.png'
import media from '../../media'
import LinkButton from '../../components/LinkButton'

const Container = styled(Section)`
  width: 100%;
  height: 730px;
`

const Wrapper = styled(Flex)`
  width: 100%;
  max-width: 1100px;
  height: 100%;
  margin: 0 auto;
  color: #fff;
  padding: 20px;
  align-items: center;
  justify-content: center;
`

const Background = styled(BackgroundImage)`
  background: url(${BlueBg}) no-repeat;
  background-size: 100% 100%;
`

const Content = styled.div`
  margin-top: 5%;
`

const Image = styled.img`
  margin-top: 12%;
  width: 50%;
  max-width: 600px;
  height: auto;

  ${media.tablet2`
         display: none;`}
`

const Heading1 = styled(Heading)`
  font-size: 45px;
  line-height: initial;

  ${media.tablet2`
        font-size: 30px`}
`

const Heading2 = styled(Heading)`
  margin: 20px 0;
  font-size: 30px;
  font-weight: 300;

  ${media.tablet`
        font-size: 20px`}
`

const Hero = () => (
  <Container>
    <Background height={'100%'}>
      <Wrapper>
        <Content>
          <Heading1 m={0} style={{ fontWeight: '600' }}>
            {"Our API Solution"}
          </Heading1>
          <Heading2 style={{ fontWeight: '300' }}>
            {'Creating travel for your business'}
          </Heading2>
          <LinkButton to={'getting-started'}>{'Get Started'}</LinkButton>
        </Content>
        <Image src={Computer} />
      </Wrapper>
    </Background>
  </Container>
)

export default Hero
