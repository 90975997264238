import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 25%;
  position: relative;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`

const ContainerContent = styled.div`
  position: sticky;
  top: 15px;
  z-index: 2;
  max-height: 100vh;
  width: 100%;
  padding-bottom: 120px;
  padding-right: 50px;
  overflow-y: auto;
`

interface SideNavContainerProps {
  children: ReactNode
}

const SideNavContainer = (props: SideNavContainerProps) => {
  const { children } = props

  return (
    <Container>
      <ContainerContent>{children}</ContainerContent>
    </Container>
  )
}

export default SideNavContainer
