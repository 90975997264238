import { useState, useCallback, Dispatch, SetStateAction } from 'react'
import { Guides } from './types'

export interface GuidesState {
  loading: boolean
  error: string
  guides: Guides
}

export const guidesInitialState: GuidesState = {
  loading: false,
  error: '',
  guides: {},
}

export interface GuidesActions {
  fetchGuides: () => void
  fetchGuidesSuccess: (guides: Guides) => void
  fetchGuidesError: () => void
}

export interface UseGuidesState {
  actions: GuidesActions
  state: GuidesState
}

export interface UseGuidesActionsProps {
  setState: Dispatch<SetStateAction<GuidesState>>
  state: GuidesState
}

export const useGuidesActions = (
  props: UseGuidesActionsProps
): GuidesActions => {
  const { setState } = props

  const fetchGuides = useCallback(
    () =>
      setState((prev: GuidesState) => ({
        ...prev,
        loading: true,
        error: '',
      })),
    [setState]
  )

  const fetchGuidesSuccess = useCallback(
    (guides: Guides) =>
      setState(() => ({
        loading: false,
        error: '',
        guides: guides,
      })),
    [setState]
  )

  const fetchGuidesError = useCallback(
    () =>
      setState(() => ({
        guides: {},
        loading: false,
        error: 'An Error Occurred while fetching guides.',
      })),
    [setState]
  )

  return {
    fetchGuides,
    fetchGuidesSuccess,
    fetchGuidesError,
  }
}

export const useGuidesState = (): UseGuidesState => {
  const [state, setState] = useState<GuidesState>(guidesInitialState)
  const actions = useGuidesActions({ setState, state })

  return {
    actions,
    state,
  }
}

export default useGuidesState
