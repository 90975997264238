import React from 'react'
import styled from 'styled-components'
import { BackgroundImage, Flex, Heading } from 'pcln-design-system'
import SupportBg from '../../assets/images/support-hero.png'
import {
  supportContact,
  supportFaq,
  supportTerminology,
} from '../../assets/icons'
import Card from './Card'
import media from '../../media'

const Background = styled(BackgroundImage)`
  background: url(${SupportBg}) no-repeat center;
  min-height: 406px;
  flex: 2 0 0;

  ${media.tablet`
        background: none;
        min-height: 300px;`}
`

const HeroWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1100px;
  justify-content: left;
  min-height: inherit;
`

const HeroContent = styled(Flex)`
  min-height: inherit;
  width: 40%;
  align-items: center;
  padding: 20px;

  ${media.tablet`
        width: 100%;`}
`

const Container = styled(Flex)`
  flex-direction: column;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
    margin-bottom: 20px;
  }
`

const ContainerBody = styled(Flex)`
  margin: 50px auto;
  max-width: 1100px;
  font-size: 14px;
  flex-wrap: wrap;

  ${media.tablet`
        flex-direction: column;
        margin-top: 0px;`}
`

const HeadingH1 = styled(Heading.h1)`
  font-weight: 600;
  font-size: 50px;
  display: block;
  line-height: 65px;
  text-align: right;

  ${media.tablet`
        text-align: center;`}
`

const HeadingH2 = styled(Heading.h2)`
  text-align: right;

  ${media.tablet`
        text-align: center;`}
`

const cardInfo = [
  {
    title: 'Terminology',
    description:
      'Get familiar with the list of terminology used in our Guides, API documentation and throughout this site.',
    icon: supportTerminology,
    link: 'See Terminology',
    href:
      'https://pricelinepartnernetwork.com/developers/pps-api/guides/guide/terminology',
  },
  {
    title: 'FAQ',
    description:
      'Have any questions? There is a good chance that one of our other partners had the same question. Try finding answers in our frequently asked questions.',
    icon: supportFaq,
    link: 'See Frequently Asked Questions',
    href:
      'https://pricelinepartnernetwork.com/developers/pps-api/guides/guide/faq',
  },
  {
    title: 'Contact Us',
    description: 'If you still need help, use the below links to contact us.',
    icon: supportContact,
    link: 'Become a Partner',
    href: 'https://pricelinepartnernetwork.com/become-a-partner',
    link2: 'Contact Account Manager',
    href2: 'https://admin.rezserver.com/contact',
  },
]

const SupportNew = () => {
  return (
    <Container>
      <Background height={'100%'}>
        <HeroWrapper>
          <HeroContent>
            <div>
              <HeadingH1 color={'blue'} style={{ fontWeight: '600' }}>
                {'Support'}
              </HeadingH1>
              <HeadingH2 style={{ fontWeight: '300' }}>
                {'How can we help?'}
              </HeadingH2>
            </div>
          </HeroContent>
        </HeroWrapper>
      </Background>
      <ContainerBody>
        {cardInfo.map((cardInfo, index) => (
          <Card key={index} {...cardInfo} />
        ))}
      </ContainerBody>
    </Container>
  )
}

export default SupportNew
