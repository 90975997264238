export interface Config {
  APPLICATION_NAME: string
  BASE_API_URL: string
  OKTA_CLIENT_ID: string
  OKTA_ISSUER: string
  PPNCOM_URL: string
  ROOT_URL: string
}

export const initialState = {
  APPLICATION_NAME: '',
  BASE_API_URL: '',
  OKTA_CLIENT_ID: '',
  OKTA_ISSUER: '',
  PPNCOM_URL: '',
  ROOT_URL: '',
}
