import { useCallback, useEffect, useState } from 'react'

export const useImageSource = (src, directory) => {
  const [source, setSource] = useState('')

  const getSource = useCallback(async () => {
    if (isSourceURL(src)) {
      setSource(src)
    } else if (src !== '') {
      try {
        const { default: source } = await import(
          `../../assets/${directory}/${src}`
        )
        setSource(source)
      } catch (exception) {
        /* eslint-disable no-console */
        console.error(exception)
        setSource('')
      }
    } else {
      setSource('')
    }
  }, [src, setSource, directory])

  useEffect(() => {
    getSource()
  }, [src, getSource])

  return { source }
}

const isSourceURL = (string) => {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export default useImageSource
