import { Config } from './entities/Config'
import { AppProvider } from './entities/App'
import { AuthProvider, OktaProvider } from './entities/Auth'
import { UsersProvider } from './entities/Users'
import { ThemeProvider } from 'pcln-design-system'
import React, { ReactNode } from 'react'

interface AppProviderProps {
  config: Config
  children: ReactNode
}

const AppProviders = (props: AppProviderProps) => {
  const { config, children } = props

  return (
    <AppProvider config={config}>
      <AuthProvider>
        <UsersProvider>
          <OktaProvider>
            <ThemeProvider>{children}</ThemeProvider>
          </OktaProvider>
        </UsersProvider>
      </AuthProvider>
    </AppProvider>
  )
}

export default AppProviders
