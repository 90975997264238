import React from 'react'
import {
  Background,
  HeroContent,
  HeadingDiv,
  HeroMainHeading,
  HeroSubHeading,
  HeroWrapper,
} from './styled'
import useImageSource from '../ImageSource'

type HeroCardProps = {
  loading: boolean,
  heroImage: string,
  title: string,
  subtitle: string,
}

const HeroCard = (props: HeroCardProps) => {
  const { loading, heroImage, title, subtitle } = props
  const heroImageRoot = 'images'
  const { source } = useImageSource(heroImage, heroImageRoot)

  return (
    <Background height={'100%'} image={source}>
      <HeroWrapper>
        {!loading && (
          <HeroContent>
            <HeadingDiv>
              <HeroMainHeading
                color={'blue'}
                style={{
                  fontWeight: '900',
                  marginBottom: 0,
                }}
              >
                {title}
              </HeroMainHeading>
              <HeroSubHeading style={{ fontWeight: '300' }}>
                {subtitle}
              </HeroSubHeading>
            </HeadingDiv>
          </HeroContent>
        )}
      </HeroWrapper>
    </Background>
  )
}

HeroCard.defaultProps = {
  loading: false,
  heroImage: '',
  title: '',
  subtitle: '',
}

export default HeroCard
