import React, { useMemo } from 'react'
import { useHeadingsContext } from '../Editor/HeadingsProvider'
import { Link } from './styled'
import SideNavContainer from './SideNavContainer'
import { Heading } from '../Editor/HeadingsProvider/useHeadingsState'
import { useGuidesContext } from '../../entities/Guides'

const HeaderLink = (props: Heading) => {
  const { id, level, text, isActive, hash } = props

  return (
    <Link href={`#${hash}`} key={id} level={level || 1} isActive={isActive}>
      {text}
    </Link>
  )
}

const SideNav = () => {
  const { headings } = useHeadingsContext()
  const { loading } = useGuidesContext()

  const links = useMemo(
    () =>
      Object.values(headings).map((heading) => {
        // @ts-ignore
        return <HeaderLink {...heading} />
      }),
    [headings]
  )

  return <SideNavContainer>{!loading && <div>{links}</div>}</SideNavContainer>
}

export default SideNav
