import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Product } from '../types'
import { useProductsContext } from '..'
import { Category } from '../Categories'
import { useAPIRequest } from '../../../api/APIRequest'
import { mapVertical } from '../../Verticals/mappers'

export interface CurrentProduct {
  currentProduct?: Product
  currentVersion?: string
  categories: Array<Category>
}

export const currentProductInitialState: CurrentProduct = {
  currentProduct: undefined,
  currentVersion: undefined,
  categories: [],
}

const useCurrentProduct = (): CurrentProduct => {
  const request = useAPIRequest()
  const { products } = useProductsContext()

  const { product, version, vertical } = useParams()
  const [productCategories, setProductCategories] = useState<Array<Category>>(
    []
  )

  const defaultProduct = Object.values(products)[0]

  const currentProductInfo: Product | undefined = useMemo(() => {
    return product ? products[product] : defaultProduct
  }, [product, products, defaultProduct])

  const requestRef = useRef(request)

  useEffect(() => {
    requestRef.current = request
  }, [request])

  useEffect(() => {
    const fetchCategories = async (vertical: string) => {
      const categories = await requestRef.current.get(
        `/guides/${vertical}/categories?products=${
          product ? product : defaultProduct.name
        }`
      )
      setProductCategories(categories.data)
    }

    if (product || defaultProduct)
      fetchCategories(mapVertical(vertical || '')).catch((err) =>
        console.error(err)
      )
  }, [product, defaultProduct, vertical])

  const currentVersion: string | undefined = useMemo(() => {
    return (
      version ||
      (currentProductInfo?.versions
        ? Object.keys(currentProductInfo.versions)[0]
        : undefined)
    )
  }, [version, currentProductInfo])

  return {
    currentProduct: currentProductInfo,
    currentVersion,
    categories: productCategories,
  }
}

export default useCurrentProduct
