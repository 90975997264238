import React from 'react'
import styled from 'styled-components'

const Section = styled.div`
  width: 100%;
  position: relative;
  height: 50vh;
  min-height: 600px;
`

const HomeSection = ({ children, ...props }) => {
  return <Section {...props}>{children}</Section>
}

export default HomeSection
