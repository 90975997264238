import { useContext, createContext, useEffect, ReactNode, useRef } from 'react'
import useHeadings, { UseHeadings } from './useHeadings'
import useHeadingsState from './useHeadingsState'
import { useMatch } from 'react-router-dom'

interface HeadingsProviderProps {
  children: ReactNode
}

export const initialState: UseHeadings = {
  setHeading: () => null,
  getHeading: () => undefined,
  resetHeadings: () => undefined,
  headings: {},
  setActive: () => null,
}

export const HeadingsContext = createContext<UseHeadings>(initialState)

export const useHeadingsContext = () => useContext(HeadingsContext)

const HeadingsProvider = (props: HeadingsProviderProps) => {
  const { children } = props
  const match = useMatch('guides/id/:guide')
  const guide = match?.params.guide

  const state = useHeadingsState()
  const value = useHeadings({ state })

  const valueRef = useRef(value)

  useEffect(() => {
    valueRef.current = value
  }, [value])

  useEffect(() => {
    valueRef.current.resetHeadings()
  }, [guide, value.resetHeadings])

  return (
    <HeadingsContext.Provider value={value}>
      {children}
    </HeadingsContext.Provider>
  )
}

export default HeadingsProvider
