import React from 'react'
import styled from 'styled-components'
import { Flex, getPaletteColor } from 'pcln-design-system'
import Section from './Section'
import media from '../../media'
import LinkButton from '../../components/LinkButton'

const Container = styled(Flex)`
  align-items: center;
  justify-content: center;
  background: ${getPaletteColor('primary.base')};
  padding: 30px;
  height: 100%;
`

const Wrapper = styled(Flex)`
  flex-flow: column;
  align-items: center;
`

const SubTitle = styled.h1`
  display: inline-block;
  width: 60%;
  color: white;
  font-weight: 100;
  font-size: 3vw;
  text-align: center;
  margin: 20px 0 30px;

  ${media.tablet`
        width: 75%;
        font-size: 30px;`}
`

const GetStarted = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <SubTitle>
            {
              "Priceline Partner Solutions enables the world's best companies to build better products"
            }
          </SubTitle>
          <LinkButton to={'/getting-started'}>Get Started</LinkButton>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default GetStarted
