import React, { useEffect, useState, useRef } from 'react'
import Nav from '../Guides/Nav'
import SideNav from '../../components/SideNav'
import HeadingsProvider from '../../components/Editor/HeadingsProvider'
import ScrollWatcher from '../../components/Markdown/ScrollWatcher/ScrollWatcher'
import Content from '../../components/Markdown/Content'
import Markdown from '../../components/Markdown'
import { useParams } from 'react-router-dom'
import { Guide as GuideEntity } from '../../entities/Guides'
import { Products } from '../../entities/Guides'
import { mapVertical } from '../../entities/Verticals/mappers'
import useGuidesAPI from '../../api/API'

type GuideProps = {
  products: Products
}

const GuideContent = (props: GuideProps) => {
  const [loading, setLoading] = useState(true)
  const [guide, setGuide] = useState<GuideEntity>({} as GuideEntity)
  const { name, vertical } = useParams()
  const { products } = props

  const api = useGuidesAPI()
  const APIRef = useRef(api)

  useEffect(() => {
    APIRef.current = api
  }, [api])

  useEffect(() => {
    const fetchGuide = async (name: string, vertical: string) => {
      setLoading(true)
      const { data } = await APIRef.current.fetchGuide(name, vertical)
      setGuide(data as GuideEntity)
      setLoading(false)
    }

    if (name && vertical) fetchGuide(name, mapVertical(vertical))
  }, [name, vertical])

  return (
    <React.Fragment>
      <ScrollWatcher />
      <Nav products={products} />
      <Content
        loading={loading}
        heroImage={guide?.heroImage}
        title={guide?.title}
        subtitle={guide?.subtitle}
      >
        <Markdown markdown={guide?.content || ''} />
      </Content>
      <SideNav />
    </React.Fragment>
  )
}

const Guide = (props: GuideProps) => {
  const { products } = props

  return (
    <HeadingsProvider>
      <GuideContent products={products} />
    </HeadingsProvider>
  )
}

export default Guide
