import { Container, ContainerContent, NavTitle } from './styled'
import React, { useMemo } from 'react'
import NavItem from './NavItem'
import { NavItem as iNavItem } from './types'

interface IconNavProps {
  title: string
  navItems: iNavItem[]
}

const IconNav = (props: IconNavProps) => {
  const { title, navItems } = props
  const renderedNavItems = useMemo(
    () =>
      navItems.map((navItem: iNavItem) => (
        <NavItem key={navItem.title} {...navItem} />
      )),
    [navItems]
  )

  return (
    <Container>
      <ContainerContent>
        <NavTitle>{title}</NavTitle>
        {renderedNavItems}
      </ContainerContent>
    </Container>
  )
}

export default IconNav
