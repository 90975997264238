import React from 'react'
import styled from 'styled-components'
import { BackgroundImage, Flex, Heading } from 'pcln-design-system'
import GettingStartedBg from '../../assets/images/getting-started-hero.png'
import {
  getStartedSignUp,
  getStartedApi,
  getStartedBuild,
  getStartedLaunch,
  getStartedLearn,
  getStartedReview,
} from '../../assets/icons'
import Card from './Card'
import media from '../../media'

const Background = styled(BackgroundImage)`
  background: url(${GettingStartedBg}) no-repeat center;
  min-height: 406px;
  flex: 2 0 0;

  ${media.tablet`
        background: none;
        min-height: 300px;`}
`

const HeroWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1100px;
  justify-content: left;
  min-height: inherit;
`

const HeroContent = styled(Flex)`
  min-height: inherit;
  width: 40%;
  align-items: center;
  padding: 20px;

  ${media.tablet`
        width: 100%;`}
`

const Container = styled(Flex)`
  flex-direction: column;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
    margin-bottom: 20px;
  }
`

const ContainerBody = styled(Flex)`
  margin: 50px auto;
  max-width: 1100px;
  font-size: 14px;
  flex-wrap: wrap;

  ${media.tablet`
        flex-direction: column;
        margin-top: 0px;`}
`

const HeadingH1 = styled(Heading.h1)`
  font-weight: 600;
  font-size: 50px;
  display: block;
  line-height: 65px;
  text-align: right;

  ${media.tablet`
        text-align: center;`}
`

const HeadingH2 = styled(Heading.h2)`
  text-align: right;

  ${media.tablet`
        text-align: center;`}
`

const cardInfo = [
  {
    title: '1. Sign up for a Priceline Partner account',
    description:
      'If you are not already a member of the Priceline Partner Solution your first step is to contact our team to get you set up with a new account.',
    icon: getStartedSignUp,
    link: 'Contact us',
    href: 'https://pricelinepartnernetwork.com/become-a-partner',
  },
  {
    title: '2. Receive your API key',
    description:
      'If you are an existing Priceline Partner who is looking to begin developing with our API, you will first need to contact your account manager to get access to an API key for your developers.',
    icon: getStartedApi,
    link: 'Contact your account manager',
    href: 'https://admin.rezserver.com/contact',
  },
  {
    title: '3. Learn about developing with our APIs',
    description:
      'Have your developers get familiar with our API guides and documentation which outline how to get access to our inventory and create new bookings.',
    icon: getStartedLearn,
    link: 'Development Guides',
    href: '/developers/guides/hotel',
    link2: 'PPS API Documentation',
    href2: '/developers/documentation',
  },
  {
    title: '4. Build your site or application',
    description:
      'Integrate dynamic hotel, rental car and flight data from multiple sources directly into your website or application.',
    icon: getStartedBuild,
    link: 'Download our developer tools',
    href: '/developers/pps-api/guides/guide/downloads-page',
  },
  {
    title: '5. Have us review your work',
    description:
      'Partners who are going to sell our travel inventory are required to meet certain display and technical requirements. Prior to the production launch Priceline must certify and sign off that all requirements outlined in this document have been met.',
    icon: getStartedReview,
    link: 'Download our certification checklists',
    href: '/developers/pps-api/guides/guide/certification-checklists',
    link2: 'Contact your account manager',
    href2: 'https://admin.rezserver.com/contact',
  },
  {
    title: '6. Launch your site',
    description:
      'Once your site or application has launched our team is still available for ongoing support. Our Back Office tool can provide sales information or custom reports on how your application is performing.',
    icon: getStartedLaunch,
    link: 'View reports',
    href: 'https://admin.rezserver.com/login',
  },
]

const GettingStarted = () => {
  return (
    <Container>
      <Background height={'100%'}>
        <HeroWrapper>
          <HeroContent>
            <div>
              <HeadingH1 color={'blue'} style={{ fontWeight: '600' }}>
                {'Ready to get started?'}
              </HeadingH1>
              <HeadingH2 style={{ fontWeight: '300' }}>
                {"Let's start building"}
              </HeadingH2>
            </div>
          </HeroContent>
        </HeroWrapper>
      </Background>
      <ContainerBody>
        {cardInfo.map((cardInfo, index) => (
          <Card key={index} {...cardInfo} />
        ))}
      </ContainerBody>
    </Container>
  )
}

export default GettingStarted
