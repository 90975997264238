import React, {
  ReactNode,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import { Security, useOktaAuth } from '@okta/okta-react'
import { useAppContext } from '../App'
import OktaUserProvider from './OktaUserProvider'
import { useAuthContext } from './AuthProvider'
import { LoginCallback } from '@okta/okta-react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
interface OktaProviderProps {
  children: ReactNode
}

interface Storage {
  storageKey: string
}

interface OktaConfig {
  clientId: string
  issuer: string
  redirectUri: string
  pkce: boolean
  tokenManager: Storage
}

export const APP_DEPENDENCY_NAME_OKTA = 'OKTA'

const OnAuth = () => {
  const { config } = useAppContext()
  const { authService, authState } = useOktaAuth()
  const { onAuth } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()

  const pathname = location.pathname.replace(/^\/login/, '')
  const redirectURL = `${config?.ROOT_URL}` + pathname
  const redirect = redirectURL || '/'
  const login = useCallback(() => {
    if (!authState?.isAuthenticated) {
      authService.login(redirect)
    }
    navigate(pathname)
  }, [authService, redirect, authState, navigate, pathname])

  const logout = useCallback(() => {
    authService.logout(config?.ROOT_URL)
  }, [authService, config])

  const onAuthRef = useRef(onAuth)

  useEffect(() => {
    onAuthRef.current = onAuth
  }, [onAuth])

  useEffect(() => {
    if (!authState.isPending) {
      onAuthRef.current(
        authState.isAuthenticated,
        authState.accessToken,
        login,
        logout
      )
    }
  }, [authState, login, logout])

  return null
}

export const OktaProvider = (props: OktaProviderProps) => {
  const { children } = props
  const {
    config,
    registerDependency,
    setDependencyInitialized,
    dependenciesInitialized,
  } = useAppContext()

  const oktaConfig: OktaConfig | null = useMemo(() => {
    if (!config) {
      return null
    }

    const redirectUri = `${window.location.origin}${config.ROOT_URL}/implicit/callback`

    return {
      clientId: config.OKTA_CLIENT_ID,
      issuer: config.OKTA_ISSUER,
      redirectUri,
      tokenManager: {
        storageKey: `ppn-docs`,
      },
    } as OktaConfig
  }, [config])

  useEffect(() => {
    registerDependency(APP_DEPENDENCY_NAME_OKTA)
  }, [registerDependency])

  useEffect(() => {
    if (oktaConfig && !dependenciesInitialized[APP_DEPENDENCY_NAME_OKTA]) {
      setDependencyInitialized(APP_DEPENDENCY_NAME_OKTA, true)
    }
  }, [oktaConfig, dependenciesInitialized, setDependencyInitialized])

  if (!dependenciesInitialized[APP_DEPENDENCY_NAME_OKTA]) {
    return <React.Fragment>{children}</React.Fragment>
  }

  return (
    <Security {...oktaConfig}>
      <OnAuth />
      <OktaUserProvider>
        <Routes>
          <Route path={`/implicit/callback`} element={<LoginCallback />} />
        </Routes>
        {children}
      </OktaUserProvider>
    </Security>
  )
}

export default OktaProvider
