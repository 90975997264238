import React from 'react'
import { Icon, NavItemContainer, Title } from './styled'
import { NavItem as iNavItem } from './types'
import ScrollToTop from '../ScrollToTop'

const NavItem = (props: iNavItem) => {
  const { title, icon, isActive, to } = props

  return (
    <ScrollToTop>
      <NavItemContainer alignItems={'center'} justifyContent={'flex-start'}>
        <Icon name={icon} />
        <Title $isActive={isActive} to={to}>
          {title}
        </Title>
      </NavItemContainer>
    </ScrollToTop>
  )
}

export default NavItem
