import { useEffect, useCallback, useState } from 'react'
import { useHeadingsContext } from '../../Editor/HeadingsProvider'
import { Headings } from '../../Editor/HeadingsProvider/useHeadingsState'

const getFirstAppearingHeading = (headings: Headings) =>
  Object.values(headings).find((heading) => {
    const { element } = heading

    if (element) {
      const rect = element.getBoundingClientRect()
      return rect.y > 0
    } else {
      return false
    }
  })

const ScrollWatcher = () => {
  const { headings, setActive } = useHeadingsContext()
  const [initialized, setInitialized] = useState(false)
  const handleScroll = useCallback(() => {
    const firstAppearingHeading = getFirstAppearingHeading(headings)

    setActive(firstAppearingHeading?.id)
  }, [setActive, headings])

  useEffect(() => {
    if (!initialized) {
      const firstHeading = getFirstAppearingHeading(headings)

      if (firstHeading) {
        setActive(firstHeading?.id)
        setInitialized(true)
      }
    }
  }, [initialized, setActive, headings])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return null
}

export default ScrollWatcher
