import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { getPaletteColor } from 'pcln-design-system'

const Container = styled.pre`
  border: 1px rgba(0, 0, 0, 0.1) solid;
  display: inline;
  line-height: 100%;
  padding: 3px;
  color: ${getPaletteColor('primary.base')};
  background: ${getPaletteColor('background.light')};
  border-radius: 3px;
  font-family: monospace;
  white-space: pre;
`

interface InlineCodeProps {
  children: ReactNode[]
}

const InlineCode = (props: InlineCodeProps) => {
  return <Container>{String(props.children)}</Container>
}

export default InlineCode
