import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { mapVertical } from '../../../entities/Verticals/mappers'
import ContentContainer from '../../../components/Markdown/Content'
import useGuidesAPI from '../../../api/API'
import { Guides } from '../../../entities/Guides'
import { Products } from '../../../entities/Guides'
import Categories from './Categories'

type ContentProps = {
  products: Products
}

const Content = (props: ContentProps) => {
  const { product, vertical } = useParams()
  const { products } = props

  const [guides, setGuides] = useState({} as Guides)
  const [loading, setLoading] = useState(true)

  const api = useGuidesAPI()
  const APIRef = useRef(api)

  const defaultProduct = Object.keys(products)[0]

  useEffect(() => {
    APIRef.current = api
  }, [api])

  useEffect(() => {
    setLoading(true)
    const fetchGuides = async (product: string, vertical: string) => {
      const { data } = await APIRef.current.fetchGuides(
        { products: [product] },
        mapVertical(vertical)
      )
      setGuides(data as Guides)
      setLoading(false)
    }

    if (product) {
      fetchGuides(product, vertical || '')
    } else {
      fetchGuides(defaultProduct, vertical || '')
    }
  }, [defaultProduct, product, products, vertical])

  const currentProduct = products[product || defaultProduct]

  return (
    <ContentContainer
      title={currentProduct?.title}
      subtitle={currentProduct?.subtitle}
      heroImage={currentProduct?.heroImage}
      loading={loading}
    >
      {!loading && (
        <Categories
          guides={guides}
          product={product}
          defaultProduct={defaultProduct}
          vertical={vertical}
        />
      )}
    </ContentContainer>
  )
}

export default Content
