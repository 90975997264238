import React from 'react'
import styled from 'styled-components'
import {
  Card as pclnCard,
  Heading,
  Flex,
  getPaletteColor,
} from 'pcln-design-system'

const Container = styled(Flex)`
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
`

const CardBox = styled(pclnCard)`
  padding: 30px 20px;
  background: ${getPaletteColor('background.lightest')};
  height: 100%;
`

const Info = styled.div`
  height: 100%;
  text-align: center;
  padding: 40px 0;
`

const Image = styled.img`
  margin: 0 auto;
  width: 120px;
  height: 120px;
`

const Title = styled(Heading.h2)`
  margin: 10px;
  font-size: 24px;
`

const Description = styled(Heading.h4)`
  padding: 5px 10px;
  margin: 0;
  font-size: 16px;
`

const Card = ({ icon, title, description, ...props }) => {
  return (
    <Container>
      <CardBox
        {...props}
        width={'100%'}
        boxShadowSize={'xl'}
        borderWidth={0}
        borderRadius={10}
      >
        <Info>
          <Image src={icon} />
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </Info>
      </CardBox>
    </Container>
  )
}

Card.defaultProps = {
  title: '',
  description: '',
}

export default Card
