import React from 'react'
import { Container } from './styled'
import { Flex, Image } from 'pcln-design-system'
import Spinner from '../../../components/Spinner'
import Logo from '../../../assets/logo/priceline-logo.png'

const LoadingLogo = () => (
  <Flex
    alignItems={'center'}
    justifyContent={'center'}
    flexDirection={'column'}
  >
    <Image src={Logo} height={100} width={100} />
    <Spinner size={1.25} />
  </Flex>
)

const Loading = () => {
  return (
    <Container justifyContent={'center'} alignItems={'center'}>
      <LoadingLogo />
    </Container>
  )
}

export default Loading
