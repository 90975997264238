import styled from 'styled-components'

export const MarkdownTheme = styled.div`
  td,
  th {
    padding: 6px;
    padding-right: 15px;
    border: 1px solid rgb(221, 221, 221);
    word-break: normal;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0px;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  th {
    font-weight: bold;
  }

  tr {
    background-color: rgb(255, 255, 255);
    border-top: 1px solid rgb(204, 204, 204);
  }

  tr:nth-child(2n) {
    background-color: rgb(250, 250, 250);
  }

  pre {
    white-space: pre-wrap;
  }
`
