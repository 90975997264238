import { User } from './Users'
import { StateErrors } from '../../Errors'

export interface StateUser extends User {
  loading: boolean
  error: string
  errors: StateErrors
}

export interface UsersState {
  error: string
  loading: boolean
  currentUser?: User
}

export const initialUsersState: UsersState = {
  error: '',
  currentUser: undefined,
  loading: false,
}
