import React from 'react'
import { Flex, Box, Heading, getPaletteColor } from 'pcln-design-system'
import styled from 'styled-components'
import Section from './Section'
import TechTrio from '../../assets/images/technology-trio.png'
import media from '../../media'

const Container = styled(Section)`
  padding: 20px;
  margin: 100px 0;
  text-align: center;
`

const BlueSpan = styled.span`
    color ${getPaletteColor('primary.base')};
`

const TechTriImg = styled.img`
  margin-top: 40px;
  width: 100%;
  max-width: 850px;
`

const TechFlex = styled(Flex)`
  margin: 0 auto;
  max-width: 800px;
  align-items: top;
  justify-content: center;
`

const Headingh4 = styled(Heading.h4)`
  ${media.mobile`
       font-size: 12px;`}
`

const TechBox = styled(Box)`
  ${media.mobile`
       font-size: 10px;`}
`

const TechP = styled.p`
  padding: 0px 20px;
`

const techCards = [
  {
    title: 'Restful API',
    description:
      'Built on simple HTTPS protocols. Get up and running in no time.',
  },
  {
    title: 'Stable',
    description:
      'Distributed data centers deliver fast, reliable feeds to your applications',
  },
  {
    title: 'Speed',
    description:
      'Lightning fast, cached rates, our APIs can supercharge your business.',
  },
]

const TechnologyHome = () => {
  return (
    <Container>
      <Heading.h2>
        Our <BlueSpan>Technology</BlueSpan>
      </Heading.h2>
      <TechTriImg src={TechTrio} />
      <TechFlex>
        {techCards.map((techCards, index) => (
          <TechBox key={index}>
            <Headingh4 color={'blue'} style={{ fontWeight: '600' }}>
              {techCards.title}
            </Headingh4>
            <TechP>{techCards.description}</TechP>
          </TechBox>
        ))}
      </TechFlex>
    </Container>
  )
}

TechnologyHome.defaultProps = {
  techCards: [],
}

export default TechnologyHome
