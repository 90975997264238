import React from 'react'
import ReactMarkdown, { Components } from 'react-markdown'
import Heading from './renderers/Heading/Heading'
import Image from './renderers/Image'
import Code from './renderers/Code'
import InlineCode from './renderers/InlineCode'
import { MarkdownTheme } from './styled'
import removeSpacesFromLinks from './removeSpacesFromLinks'
import remarkGfm from 'remark-gfm'

interface MarkdownProps {
  markdown: string
}

const components: Components = {
  h1: Heading,
  h2: Heading,
  h3: Heading,
  h4: Heading,
  h5: Heading,
  h6: Heading,
  img: Image,
  code: ({ inline, className, children }) => {
    return (
      <>
        {inline ? (
          <InlineCode children={children} />
        ) : (
          <Code className={className} children={children} />
        )}
      </>
    )
  },
}

const Markdown = (props: MarkdownProps) => {
  const { markdown } = props
  const markdownWithoutLinkSpaces = removeSpacesFromLinks(markdown)
  return (
    <MarkdownTheme>
      <ReactMarkdown
        children={markdownWithoutLinkSpaces}
        components={components}
        remarkPlugins={[remarkGfm]}
      />
    </MarkdownTheme>
  )
}

export default Markdown
