import React, { createContext, ReactNode, useContext } from 'react'
import useCurrentProduct, {
  CurrentProduct,
  currentProductInitialState,
} from './useCurrentProduct'

export const CurrentProductContext = createContext<CurrentProduct>(
  currentProductInitialState
)

export const useCurrentProductContext = (): CurrentProduct =>
  useContext(CurrentProductContext)

export interface CurrentProductProviderProps {
  children: ReactNode
}

export const CurrentProductProvider = (props: CurrentProductProviderProps) => {
  const { children } = props
  const value = useCurrentProduct()

  return (
    <CurrentProductContext.Provider value={value}>
      {children}
    </CurrentProductContext.Provider>
  )
}

export default CurrentProductProvider
