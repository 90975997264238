import { StyledImage } from './styled'
import useImageSource from '../../../ImageSource'
import { Key } from 'react'

interface ImageProps {
  src?: string
  alt?: string
  className?: string
  key?: Key | null
}

const contentfulAssetPrefix = '//images'
const imageRoot = 'images'
const protocol = 'https://'

const Image = (props: ImageProps) => {
  const { alt, src = '', className, key } = props

  const markdownSource = src.replace(/^\/{1,2}/i, '')
  const { source } = useImageSource(
    isContentfulAsset(src) ? protocol + markdownSource : markdownSource,
    imageRoot
  )

  return source ? (
    <StyledImage className={className} src={source} alt={alt} key={key} />
  ) : (
    <></>
  )
}

const isContentfulAsset = (src: string) => {
  return src.startsWith(contentfulAssetPrefix)
}

export default Image
