import styled, { keyframes } from 'styled-components'
import { getPaletteColor } from 'pcln-design-system'

const zoomIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`
const bounceOver = keyframes`
  0% {
    transform: rotate(0deg) translate(0em, 0em);
  }
  75% {
    transform: rotate(90deg) translate(0em, -2em);
  }
  100% {
    transform: rotate(90deg) translate(0em, -2em);
  }
`
const moveOver = keyframes`
  from {
    transform: translate(2em, 0em);
  }
  to {
    transform: translate(4em, 0em);
  }
`

interface ContainerProps {
  size: number
}

export const Container = styled.div`
  position: relative;
  width: 5em;
  height: 1.75em;
  font-size: ${(props: ContainerProps) => props.size * 16}px;
  > div {
    background-color: ${getPaletteColor('primary.base')};
    border-radius: 50%;
    height: 1em;
    width: 1em;
    position: absolute;
    bottom: 0;
    animation: ${zoomIn} 0.56s linear infinite;
    &:nth-child(2) {
      animation-name: ${bounceOver};
    }
    &:nth-child(3) {
      animation-name: ${moveOver};
      animation-timing-function: ease-in;
    }
    &:last-child {
      animation-direction: reverse;
      right: 0;
    }
  }
`
