import { useMemo } from 'react'
import { GuideFilters } from '../entities/Guides/types'
import { APIRequest, RequestConfig, useAPIRequest } from './APIRequest'
import { loadProgressBar } from 'axios-progress-bar'

export interface API {
  fetchCategories: (product: string, vertical: string) => Promise<any>
  fetchGuides: (filters: GuideFilters, vertical: string) => Promise<any>
  fetchGuidesProducts: (vertical: string) => Promise<any>
  fetchDocumentationProducts: (vertical: string) => Promise<any>
  fetchGuide: (name: string, vertical: string) => Promise<any>
  fetchDocumentationSchema: (
    product: string,
    vertical: string,
    version: string
  ) => Promise<any>
}

const filtersToParams = (obj: any): string =>
  Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&')

export const GuidesAPI = (): API => {
  const requestConfig: RequestConfig = useMemo(
    () => ({
      onInit: (request: APIRequest) =>
        loadProgressBar({ showSpinner: false }, request),
    }),
    []
  )

  const request = useAPIRequest(requestConfig)

  const fetchGuides = (filters: GuideFilters, vertical: string) =>
    request.get(`/guides/${vertical}?${filtersToParams(filters)}`)

  const fetchGuidesProducts = (vertical: string) => {
    return request.get(`/guides/${vertical}/products`)
  }

  const fetchGuide = (name: string, vertical: string) => {
    return request.get(`/guide/${vertical}?name=${name}`)
  }

  const fetchCategories = (product: string, vertical: string) => {
    return request.get(`/guides/${vertical}/categories?products=${product}`)
  }

  const fetchDocumentationProducts = (vertical: string) => {
    return request.get(`/documentation/${vertical}/products`)
  }

  const fetchDocumentationSchema = (
    product: string,
    vertical: string,
    version: string
  ) => {
    return request.get(
      `/documentation/schema/${vertical}/${product}/${version}`
    )
  }

  return {
    fetchCategories,
    fetchGuides,
    fetchGuide,
    fetchGuidesProducts,
    fetchDocumentationProducts,
    fetchDocumentationSchema,
  }
}

export default GuidesAPI
