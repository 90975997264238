import styled from 'styled-components'
import { getPaletteColor, Flex } from 'pcln-design-system'

export const Container = styled(Flex)`
  position: relative;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: 100%;
  position: absolute;
  left: -50px;
  width: 50px;
  top: 0;
  bottom: 0;
`

export const Icon = styled.span`
  padding: 10px;
  opacity: 0.5;
  visibility: hidden;
`

export const Link = styled.a`
  font-size: inherit;
  text-decoration: none;
  color: ${getPaletteColor('primary.base')};

  &:hover ${Icon} {
    visibility: visible;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }
`
