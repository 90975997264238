import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyles from './GlobalStyles'
import AppProviders from './AppProviders'
import PPNDocs from './PPNDocs'
import useConfig from './useConfig'

const App = () => {
  const config = useConfig()

  if (!config) {
    return null
  }

  return (
    <BrowserRouter basename={config.ROOT_URL}>
      <AppProviders config={config}>
        <GlobalStyles />
        <PPNDocs />
      </AppProviders>
    </BrowserRouter>
  )
}

export default App
