import styled, { css } from 'styled-components'
import { getPaletteColor } from 'pcln-design-system'

interface LinkProps {
  level: number
  isActive?: boolean
}

const activeStyles = css`
  color: ${getPaletteColor('primary.base')};
  border-left: 1px solid ${getPaletteColor('primary.base')};
`

export const Link = styled.a<LinkProps>`
  text-transform: capitalize;
  color: ${getPaletteColor('text.light')};
  border-left: 1px solid ${getPaletteColor('border.base')};
  cursor: pointer;
  font-size: 14px;
  padding: 5px 0px 5px 25px;
  padding-left: ${({ level }) => `${level * 25}px`};
  text-align: left;
  display: block;
  height: initial;
  font-weight: normal;
  text-decoration: none;

  ${({ isActive }) => {
    return isActive ? activeStyles : ''
  }}

  &:active {
    text-decoration: none;
    color: ${getPaletteColor('primary.base')};
  }

  &:hover {
    ${activeStyles};
  }
`
