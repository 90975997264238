import React from 'react'
import { Heading, Flex } from 'pcln-design-system'
import styled from 'styled-components'
import Card from './Card'
import media from '../../media'
import Section from './Section'
import { partnershipHome, inventoryHome, fastHome } from '../../assets/icons'

const Container = styled(Section)`
  margin-top: 40px;
  text-align: center;
`

const CardsContainer = styled(Flex)`
  margin: 20px auto;
  max-width: 1100px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  padding: 0;

  ${media.tablet`
        flex-flow: column;`}
`

const cards = [
  {
    title: 'PERFORMANCE',
    description:
      'Agile Development Platform - A/B Testing, speed and performance.',
    icon: fastHome,
  },
  {
    title: 'PARTNERSHIPS',
    description:
      'Business Development, Marketing, Accounting & Technology Support.',
    icon: partnershipHome,
  },
  {
    title: 'INVENTORY',
    description: 'Over 980,000 properties, exclusive discounts and more.',
    icon: inventoryHome,
  },
]

const Cards = () => {
  return (
    <Container>
      <Heading.h4>REASONS</Heading.h4>
      <Heading.h2>Why Choose Us?</Heading.h2>
      <CardsContainer>
        {cards.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </CardsContainer>
    </Container>
  )
}

export default Cards
