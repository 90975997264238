import React, { ReactNode } from 'react'

const onClick = () => {
  window.scrollTo(0, 0)
}

interface ScrollToTopProps {
  children: ReactNode
  className?: string
}

const ScrollToTop = (props: ScrollToTopProps) => {
  const { children, className } = props

  return (
    <div aria-hidden="true" onClick={onClick} className={className}>
      {children}
    </div>
  )
}

export default ScrollToTop
