import React, {
  ReactNode,
  useState,
  useCallback,
  createContext,
  useContext,
  useMemo,
  useEffect,
  useRef,
} from 'react'
import { useAppContext } from '../App'

interface AuthProviderProps {
  children: ReactNode
}

interface AuthContextState {
  isAuthorized: boolean
  token: string
  login: () => void
  logout: () => void
  onAuth: (
    isAuthorized: boolean,
    token: string,
    login: () => void,
    logout: () => void
  ) => void
}

export const AuthContext = createContext<AuthContextState>({
  isAuthorized: false,
  token: '',
  onAuth: () => null,
  login: () => null,
  logout: () => null,
})

export const useAuthContext = () => useContext<AuthContextState>(AuthContext)

export const APP_DEPENDENCY_NAME_AUTH = 'AUTH'

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props
  const [state, setState] = useState({
    token: '',
    isAuthorized: false,
    login: () => null,
    logout: () => null,
  })
  const { registerDependency, setDependencyInitialized } = useAppContext()
  const registerDependencyRef = useRef(registerDependency)

  const onAuth = useCallback(
    (isAuthorized: boolean, token: string, login, logout) => {
      setState((prev) => ({
        ...prev,
        isAuthorized,
        token,
        login,
        logout,
      }))
      setDependencyInitialized(APP_DEPENDENCY_NAME_AUTH, true)
    },
    [setDependencyInitialized]
  )

  const value: AuthContextState = useMemo(
    () => ({
      ...state,
      onAuth,
    }),
    [onAuth, state]
  )

  useEffect(() => {
    registerDependencyRef.current = registerDependency
  }, [registerDependency])

  useEffect(() => {
    registerDependencyRef.current(APP_DEPENDENCY_NAME_AUTH)
  }, [])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.defaultProps = {
  children: null,
}

export default AuthProvider
