import React, { createContext, ReactNode, useContext } from 'react'
import useUsers, { UseUsers } from '../useUsers/useUsers'
import utilizeUsersState, {
  UseUsersState,
} from '../useUsersState/useUsersState'
import { APIRequest } from '../../../api/APIRequest'

export interface UsersProviderProps {
  children: ReactNode
  onRequestInit?: (request: APIRequest) => void
  state?: UseUsersState
}

const initialState: UseUsers = {
  error: '',
  loading: false,
  currentUser: undefined,
  setCurrentUser: () => null,
}

export const UsersContext = createContext<UseUsers>(initialState)

export const useUsersContext = () => useContext(UsersContext)

export const UsersProvider = (props: UsersProviderProps) => {
  const { children, state: propState } = props
  const state: UseUsersState = propState || utilizeUsersState()
  const value: UseUsers = useUsers({ state })

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
}
