import React from 'react'
import { Container, Title, SubTitle, Link } from './styled'
import { scrollToTop } from '../../utils/scrollToTop'

import { useParams } from 'react-router-dom'
interface GuideCardProps {
  title?: string
  subtitle?: string
  name?: string
}

const GuideCard = (props: GuideCardProps) => {
  const { title, subtitle } = props

  return (
    <Container boxShadowSize={'sm'}>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </Container>
  )
}

export const GuideCardLink = (props: GuideCardProps) => {
  const { name } = props
  const { vertical } = useParams()

  return (
    <Link
      to={`/${vertical}/guides/guide/${name}`}
      key={`${vertical}-${name}`}
      onClick={scrollToTop}
    >
      <GuideCard {...props} />
    </Link>
  )
}

GuideCard.defaultProps = {
  title: '',
  subtitle: '',
  name: '',
  product: '',
  vertical: '',
}
