import { Container, Name, NavLink } from './styled'

interface ProductProps {
  isActive: boolean
  icon?: string
  name?: string
  title?: string
}

const Product = (props: ProductProps) => {
  const { name, isActive, title } = props

  return (
    <NavLink to={`/documentation/${name}`}>
      <Container isActive={isActive}>
        <Name>{title}</Name>
      </Container>
    </NavLink>
  )
}

Product.defaultProps = {
  icon: '',
  name: '',
  isActive: false,
}

export default Product
