import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

declare global {
  interface Window {
    REACT_APP_LD_CLIENT_ID: string
  }
}

const LDclientSideID =
  process.env.NODE_ENV === 'production'
    ? window.REACT_APP_LD_CLIENT_ID
    : process.env.REACT_APP_LD_CLIENT_ID

const INITIAL_USER = 'ppn-docs'
const ldUser = {
  key: INITIAL_USER,
  name: INITIAL_USER,
  anonymous: false,
}

;(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LDclientSideID || '',
    user: ldUser,
  })

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
