import { useEffect, useRef, useState } from 'react'
import { RedocStandalone, Schema } from 'redoc'
import Loading from './Loading'
import { useParams } from 'react-router-dom'
import useGuidesAPI from '../../../api/API'
import { apiVertical } from '../../../entities/Verticals/constants'
import { useVersion, mapProduct } from '../mappers'

const options = {
  hideLoading: true,
  expandResponses: 'all',
  hideDownloadButton: true,
}

const Content = () => {
  const [schema, setSchema] = useState({} as Schema)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const version = useVersion()
  const api = useGuidesAPI()

  const APIRef = useRef(api)

  // TODO - PPN-66526 Replace with useCallback
  useEffect(() => {
    APIRef.current = api
  }, [api])
  const { product } = useParams()

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        setLoading(true)
        setError(undefined)
        const { data } = await APIRef.current.fetchDocumentationSchema(
          mapProduct(product),
          apiVertical,
          version
        )
        setSchema(data)
        setLoading(false)
      } catch (exception) {
        setError(exception as Error)
      }
    }
    fetchSchema()
  }, [APIRef, product, version])

  if (error) {
    return <p>An error occured</p>
  }

  return (
    <>
      {loading && <Loading />}
      {!loading && !isEmptyObject(schema) && (
        <RedocStandalone options={options} spec={schema} />
      )}
    </>
  )
}

const isEmptyObject = (obj: Object): boolean => Object.keys(obj).length === 0

export default Content
