import React from 'react'
import { Link } from './styled'
import ScrollToTop from '../ScrollToTop'

const LinkButton = (props) => {
  const { children, size, cypressName, className, ...otherProps } = props

  return (
    <ScrollToTop>
      <Link cypressName={cypressName} {...otherProps}>
        {children}
      </Link>
    </ScrollToTop>
  )
}

export default LinkButton
