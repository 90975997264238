import { useEffect, useRef } from 'react'
import { User, UsersState } from '../types'
import { UseUsersState } from '../useUsersState'

export interface UseUsers extends UsersState {
  setCurrentUser: (user?: User) => void
}

export interface UseUsersProps {
  state: UseUsersState
}

const useUsers = (props: UseUsersProps): UseUsers => {
  const {
    state: { state, actions },
  } = props
  const actionsRef = useRef(actions)

  useEffect(() => {
    actionsRef.current = actions
  }, [actions])

  return {
    ...state,
    setCurrentUser: actions.setCurrentUser,
  }
}

export default useUsers
