import React from 'react'
import { PPNNav } from '@pcln/chronos-react-components'
import { useActiveRoute } from '../../routes'
import { useAppContext } from '../../entities/App'
import { useAuthContext } from '../../entities/Auth'
import { useUsersContext } from '../../entities/Users'
import ScrollToTop from '../ScrollToTop'

const Nav = () => {
  const activeRoute = useActiveRoute()
  const { config } = useAppContext()
  const { isAuthorized, logout, login } = useAuthContext()
  const { currentUser } = useUsersContext()

  if (!config) return <></>

  return (
    <ScrollToTop>
      <PPNNav
        activeName={activeRoute?.title || 'Menu'}
        appUrl={`${config?.PPNCOM_URL}/developers`}
        baseUrl={config.PPNCOM_URL}
        loggedIn={isAuthorized}
        user={{
          firstName: currentUser?.firstName || '',
          userGroups: currentUser?.groups,
        }}
        onLogOut={logout}
        onLogIn={login}
        isPending={false}
      />
    </ScrollToTop>
  )
}

export default Nav
