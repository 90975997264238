import { User, UsersState } from '../types'
import { useCallback } from 'react'
import { UseUserActionsProps } from '../useUsersState'

export interface UsersActions {
  setCurrentUser: (user?: User) => void
}

export const useUsersActions = (props: UseUserActionsProps): UsersActions => {
  const { setState } = props

  const setCurrentUser = useCallback(
    (user?: User) =>
      setState((prev: UsersState) => ({
        ...prev,
        currentUser: user,
      })),
    [setState]
  )
  return {
    setCurrentUser,
  }
}
