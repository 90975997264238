import { useRef, useMemo, useEffect, useState } from 'react'
import { Heading as PclnHeading } from 'pcln-design-system'
import { useHeadingsContext } from '../../../Editor/HeadingsProvider'
import { useLocation } from 'react-router-dom'
import { Container, Icon, Link, IconContainer } from './styled'
import slugify from 'slugify'

interface HeadingProps {
  level: number // The Heading size: h1 - h6
}

const getUniqueID = () =>
  Date.now().toString(36) +
  Math.random().toString(36).substr(2, 5).toUpperCase()

const Heading = (props: HeadingProps) => {
  const { level } = props
  const ref = useRef<HTMLAnchorElement>(null)
  const [id, setId] = useState('')
  const [text, setText] = useState('')
  const { hash: locationHash } = useLocation()
  const { headings, setHeading, getHeading } = useHeadingsContext()
  const RenderHeading = useMemo(() => PclnHeading[`h${level}`], [level])

  const heading = useMemo(() => getHeading(id), [id, getHeading])
  const hash = useMemo(() => locationHash.substring(1, locationHash.length), [
    locationHash,
  ])

  useEffect(() => {
    if (ref.current && !heading && !id) {
      const text = ref.current.innerText
      setId(getUniqueID())
      setText(text)
    }
  }, [id, heading, setId, setText])

  useEffect(() => {
    if (!heading && ref.current && id && text) {
      setHeading({ id, text, level, element: ref.current })
    }
  }, [id, text, level, heading, headings, setHeading])

  useEffect(() => {
    if (heading && !heading.hash && id) {
      let count = 0
      Object.values(headings).some((currentHeading) => {
        const isCurrentElement = heading.id === currentHeading.id

        if (!isCurrentElement && heading.text === currentHeading.text) {
          count++
        }

        return heading.id === currentHeading.id
      })

      const slug = slugify(heading.text ? heading.text.toLowerCase() : '')
      const hash = `${slug}${count ? `-${count}` : ''}`

      setHeading({ ...heading, hash })
    }
  }, [heading, headings, id, setHeading])

  useEffect(() => {
    if (heading?.hash && locationHash && heading.hash === hash && ref.current) {
      ref.current.scrollIntoView()
    }
  }, [heading?.hash, locationHash, ref, hash])

  return (
    <Link ref={ref} id={heading?.hash} href={`#${heading?.hash}`}>
      <Container>
        <IconContainer>
          <Icon>#</Icon>
        </IconContainer>
        <RenderHeading id={heading?.id} {...props} />
      </Container>
    </Link>
  )
}

export default Heading
