import React, { createContext, ReactNode, useContext } from 'react'
import { App, appInitializedInitialState, AppInitializedProps } from './types'
import { Config } from '../Config'
import useAppInitialized from './useAppInitialized'

interface AppProviderProps extends AppInitializedProps {
  config?: Config
  children?: ReactNode
}

const initialState = {
  config: undefined,
  ...appInitializedInitialState,
}

export const AppContext = createContext<App>(initialState)

export const useAppContext = (): App => useContext<App>(AppContext)

const AppProvider = (props: AppProviderProps) => {
  const { children, config } = props
  const appInitialized = useAppInitialized({ config })

  return (
    <AppContext.Provider
      value={{
        config,
        ...appInitialized,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

AppProvider.defaultProps = {
  config: undefined,
  onInitialized: () => null,
  children: null,
}

export default AppProvider
