import { Config } from '../../Config'
import { initialState as configInitialState } from '../../Config'

export const dependenciesInitializedInitialState: DependenciesInitialized = {}

export interface DependenciesInitialized {
  [name: string]: boolean
}

export interface AppInitialized {
  dependenciesInitialized: DependenciesInitialized
  registerDependency: (name: string) => void
  setDependencyInitialized: (name: string, initialized: boolean) => void
  initialized: boolean
}

export const appInitializedInitialState: AppInitialized = {
  dependenciesInitialized: dependenciesInitializedInitialState,
  initialized: false,
  registerDependency: () => null,
  setDependencyInitialized: () => null,
}

export interface App extends AppInitialized {
  config?: Config
}

export const initialState: App = {
  ...appInitializedInitialState,
  config: configInitialState,
}

export interface AppInitializedProps {
  onInitialized?: (initialized: boolean) => void
  config?: Config
}
