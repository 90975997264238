import React from 'react'
import { Icon } from './styled'
import useImageSource from '../ImageSource'

export interface LoadingIconProps {
  className: string
  name: string
}

const LoadingIcon = (props: LoadingIconProps) => {
  const { name, className } = props

  const iconRoot = 'icons'
  const { source } = useImageSource(name, iconRoot)

  return <Icon className={className} src={source} />
}

LoadingIcon.defaultProps = {
  name: '',
  className: '',
}

export default LoadingIcon
