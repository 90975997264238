import styled from 'styled-components'
import { getPaletteColor } from 'pcln-design-system'

interface LinkProps {
  isActive: boolean
}

export const Link = styled.a`
  font-size: 14px;
  color: ${(props: LinkProps) =>
    props.isActive
      ? getPaletteColor('primary.base')
      : getPaletteColor('text.light')};
  text-decoration: none;

  ${(props: LinkProps) => (props.isActive ? `font-weight: 600;` : '')}
`
