import styled, { css } from 'styled-components'
import { Flex, getPaletteColor, Heading } from 'pcln-design-system'
import media from '../../media'
import LoadingIcon from '../LoadingIcon'
import { Link } from 'react-router-dom'

// @ts-ignore
const tablet = media.tablet

export const Container = styled(Flex)`
  position: relative;
  border-right: 1px ${getPaletteColor('border.base')} solid;
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 200px;

  ${tablet`       
        width: 100%;
        justify-content: left;
        border-right: 0px;
        margin-bottom: 0px;
        padding-bottom: 40px;
        border-bottom: 1px ${getPaletteColor('border.base')} solid;`}
`

export const ContainerContent = styled.div`
  padding: 0px 40px 0 10px;
  position: sticky;
  top: 10px;
  max-height: 100vh;
  z-index: 2;
  padding-bottom: 120px;
  overflow-y: auto;

  ${tablet`       
        padding: 0 40px;`}
`

export const NavTitle = styled(Heading.h3)`
  margin: 0;
`

export const NavItemContainer = styled(Flex)`
  padding: 5px 10px;
`

export const Icon = styled(LoadingIcon)`
  height: 40px;
  width: 40px;
  background: ${getPaletteColor('background.lightest')};
  margin-right: 15px;
`

interface TitleProps {
  $isActive: boolean
}

const active = css`
  font-weight: bold;
  color: ${(props) => getPaletteColor('primary.base')(props)};
`

export const Title = styled(Link)<TitleProps>`
  color: ${(props) => getPaletteColor('text.light')(props)};
  text-transform: capitalize;
  text-decoration: none;

  ${({ $isActive }) => ($isActive ? active : '')}
`
