import React from 'react'
import {
  Background,
  HeroWrapper,
  HeroContent,
  Container,
  ContainerBody,
  SubContainerBody,
  HeadingH1,
  HeadingH2,
  HeadingH3,
} from './styled'
import DevToolLandIcon from '../../assets/icons/dev-tools.svg'
import DocLandIcon from '../../assets/icons/api-docs.svg'
import GuideLandIcon from '../../assets/icons/development-guides.svg'
import guidesHotel from '../../assets/icons/guides-hotel.svg'
import guidesCar from '../../assets/icons/guides-car.svg'
import guidesAir from '../../assets/icons/guides-air.svg'
import Card from '../../components/Card/Card'
import CardTopic from '../../components/Card/CardTopic'

const BaseURL = `${window.location.origin}/developers`

const cardInfo = [
  {
    title: 'Development Guides',
    description: '',
    icon: GuideLandIcon,
    link: 'Development Guides',
    href: `${BaseURL}/pps-api/guides`,
  },
  {
    title: 'API Documentation',
    description: '',
    icon: DocLandIcon,
    link: 'API Documentation',
    href: `${BaseURL}/documentation`,
  },
  {
    title: 'Developer Tools',
    description: '',
    icon: DevToolLandIcon,
    link: 'Developer Tools',
    href: `${BaseURL}/pps-api/guides/guide/downloads-page`,
  },
]

const cardReleaseLog = [
  {
    title: 'Hotel API Change Log',
    description: '',
    icon: guidesHotel,
    link: 'Hotel API Change Log',
    href: `${BaseURL}/pps-api/guides/guide/hotel-api-updates`,
  },
  {
    title: 'Flight API Change Log',
    description: '',
    icon: guidesAir,
    link: 'Flight API Change Log',
    href: `${BaseURL}/pps-api/guides/guide/flight-api-updates`,
  },
]

const cardCheckList = [
  {
    title: 'Hotel API Checklist',
    description: '',
    icon: guidesHotel,
    link: 'Hotel API Checklist',
    href: `${BaseURL}/pps-api/guides/guide/hotel-checklist`,
  },
  {
    title: 'Car API Checklist',
    description: '',
    icon: guidesCar,
    link: 'Car API Checklist',
    href: `${BaseURL}/pps-api/guides/guide/car-checklist`,
  },
  {
    title: 'Flight API Checklist',
    description: '',
    icon: guidesAir,
    link: 'Flight API Checklist',
    href: `${BaseURL}/pps-api/guides/guide/air-checklist`,
  },
]

const LandingPage = () => {
  return (
    <Container>
      <Background height={'100%'}>
        <HeroWrapper>
          <HeroContent>
            <div>
              <HeadingH1 color={'white'} style={{ fontWeight: '500' }}>
                {'Explore our Guides and Documentation'}
              </HeadingH1>
              <HeadingH2 style={{ fontWeight: '300' }}>{''}</HeadingH2>
            </div>
          </HeroContent>
        </HeroWrapper>
      </Background>
      <ContainerBody>
        {cardInfo.map((cardInfo, index) => (
          <Card key={index} {...cardInfo} />
        ))}
      </ContainerBody>
      <SubContainerBody>
        <div>
          <HeadingH2 style={{ fontWeight: '600' }}>
            {'Check out the latest updates in our APIs'}
          </HeadingH2>
        </div>
      </SubContainerBody>
      <SubContainerBody>
        {cardReleaseLog.map((cardInfo, index) => (
          <CardTopic key={index} {...cardInfo} />
        ))}
      </SubContainerBody>
      <SubContainerBody>
        <div>
          <HeadingH2 style={{ fontWeight: '600' }}>
            {
              'Signing up as a new partner? Complete and submit the onboarding checklists for review.'
            }
          </HeadingH2>
          <HeadingH3 style={{ fontWeight: '300' }}>
            {
              '1 - Download the checklist(s) below for the product(s) that you are signing up for.'
            }
          </HeadingH3>
          <HeadingH3 style={{ fontWeight: '300' }}>
            {
              '2 - Follow the checklist instructions and attach your implementation samples.'
            }
          </HeadingH3>
          <HeadingH3 style={{ fontWeight: '300' }}>
            {'3 - Send to your Account Manager for review.'}
          </HeadingH3>
        </div>
      </SubContainerBody>
      <SubContainerBody>
        {cardCheckList.map((cardInfo, index) => (
          <CardTopic key={index} {...cardInfo} />
        ))}
      </SubContainerBody>
    </Container>
  )
}

export default LandingPage
