import styled from 'styled-components'
import { BackgroundImage, Flex, Heading } from 'pcln-design-system'
import LandingBg from '../../assets/images/blue_bg_landing.png'
import media from '../../media'

export const Background = styled(BackgroundImage)`
  background: url(${LandingBg}) no-repeat center;
  min-height: 406px;
  flex: 2 0 0;

  ${media.tablet`
      background: none;
      min-height: 300px;`}
`

export const HeroWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1100px;
  justify-content: left;
  min-height: inherit;
`

export const HeroContent = styled(Flex)`
  min-height: inherit;
  align-items: center;
  padding: 20px;

  ${media.tablet`
      width: 100%;`}
`

export const Container = styled(Flex)`
  flex-direction: column;
  margin-bottom: 50px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
    margin-bottom: 20px;
  }
`

export const ContainerBody = styled(Flex)`
  margin: 50px auto;
  max-width: 1100px;
  font-size: 14px;
  flex-wrap: wrap;
  justify-content: center;

  ${media.tablet`
      flex-direction: column;
      margin-top: 0px;`}
`

export const SubContainerBody = styled(Flex)`
  margin: 50px auto;
  margin-top: -20px;
  max-width: 1100px;
  flex-wrap: wrap;
  justify-content: center;

  ${media.tablet`
      flex-direction: column;
      margin-top: 0px;`}
`

export const HeadingH1 = styled(Heading.h1)`
  font-weight: 600;
  font-size: 50px;
  display: block;
  line-height: 65px;
  text-align: right;

  ${media.tablet`
      text-align: center;`}
`

export const HeadingH2 = styled(Heading.h2)`
  text-align: center;
  font-size: 20px;

  ${media.tablet`
      text-align: center;`}
`

export const HeadingH3 = styled(Heading.h3)`
  text-align: center;
  font-size: 14px;

  ${media.tablet`
      text-align: center;`}
`
