import { useEffect, useState } from 'react'
import { Config } from './entities/Config'
import axios from 'axios'

interface getConfigProps {
  callback: (config?: Config) => any
}

const getConfig = async (props: getConfigProps) => {
  const { callback } = props

  try {
    const { data } = await axios.get('/developers/config')

    callback(data)
    return
  } catch (exception) {
    console.error(exception)
  }

  callback()
}

const useConfig = () => {
  const [config, setConfig] = useState<Config | undefined>()

  useEffect(() => {
    getConfig({
      callback: (config?: Config) => {
        setConfig(config)
      },
    })
  }, [setConfig])

  return config
}

export default useConfig
