import { useState, useCallback, Dispatch, SetStateAction } from 'react'

const initialState: HeadingsState = {
  headings: {},
}

export interface Heading {
  level?: number
  id: string
  text?: string
  element?: HTMLAnchorElement
  isActive?: boolean
  hash?: string
}

export interface HeadingsState {
  headings: Headings
}

export interface Headings {
  [name: string]: Heading
}

export interface HeadingsActions {
  getHeading: (id: string) => Heading | undefined
  setHeading: (heading: Heading) => void
  setActive: (id?: string) => void
  resetHeadings: () => void
}

export interface UseHeadingsState {
  actions: HeadingsActions
  state: HeadingsState
}

export interface UseHeadingsActionsProps {
  state: HeadingsState
  setState: Dispatch<SetStateAction<HeadingsState>>
}

const useHeadingsActions = (
  props: UseHeadingsActionsProps
): HeadingsActions => {
  const { state, setState } = props

  const setHeading = useCallback(
    (heading: Heading) =>
      setState((prev: HeadingsState) => ({
        ...prev,
        headings: {
          ...prev.headings,
          [heading.id]: {
            ...prev.headings[heading.id],
            ...heading,
          },
        },
      })),
    [setState]
  )

  const setActive = useCallback(
    (id?: string) =>
      setState((prev: HeadingsState) =>
        id
          ? {
              ...prev,
              headings: Object.values(prev.headings).reduce(
                (headings: Headings, currentHeading: Heading) => ({
                  ...headings,
                  [currentHeading.id]: {
                    ...currentHeading,
                    isActive: id === currentHeading.id,
                  },
                }),
                {} as Headings
              ),
            }
          : prev
      ),
    [setState]
  )

  const getHeading = useCallback((id) => state.headings[id], [state.headings])

  const resetHeadings = useCallback(
    () =>
      setState((prev) => ({
        ...prev,
        headings: {},
      })),
    [setState]
  )

  return {
    setHeading,
    getHeading,
    resetHeadings,
    setActive,
  }
}

const useHeadingsState = (): UseHeadingsState => {
  const [state, setState] = useState<HeadingsState>(initialState)
  const actions = useHeadingsActions({ state, setState })

  return {
    state,
    actions,
  }
}

export default useHeadingsState
