import styled from 'styled-components'
import {getPaletteColor} from 'pcln-design-system'

export const Container = styled.div`
  min-height: 100vh;
  position: relative;

  h1 {
    color: ${getPaletteColor('primary.base')};
    font-weight: 500;
    margin-block-end: 0em;
    margin-block-start: 0em;
  }

  h2 {
    color: ${getPaletteColor('primary.base')};
  }

  code {
    color: rgba(51,51,51,0.9);
  }

  span.dzovVA, span.dCAzha, span.kQVfnv {
    color: #0068efc2;
  }

  div.OTNxA {
    padding-bottom: 0px;
  }

`


