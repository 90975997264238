import styled from 'styled-components'
import {
  BackgroundImage,
  Flex,
  getPaletteColor,
  Heading,
} from 'pcln-design-system'
import media from '../../media'

export const Background = styled(BackgroundImage)`
  min-height: 250px;
  max-height: 250px;
  margin-bottom: 20px;
  border-radius: 10px;

  ${media.tablet`
        background: none;
        padding: 0;
        margin: 0;
        min-height: 0;`}
`
export const HeroWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  justify-content: left;
  align-items: center;
  min-height: inherit;
`

export const HeroContent = styled(Flex)`
  min-height: inherit;
  height: 100%;
  margin-left: 40px;
  width: 45%;
  align-items: center;

  ${media.tablet`
        width: 100%;
        margin-left: 0px;`}
`

export const HeadingDiv = styled.div`
  width: 45% ${media.tablet`
        width: 100%
        margin-bottom: 20px;`};
`

export const HeroMainHeading = styled(Heading.h3)`
  font-size: 35px;
  border-bottom: 0 solid #000;
  word-break: break-word;
  margin: 0;
  line-height: initial;

  ${media.tablet`
        font-size: 30px;`}
`

export const HeroSubHeading = styled(Heading.h4)`
  font-size: 20px;
  margin: 0;
  color: ${getPaletteColor('text.light')};
  word-break: break-word;
`
