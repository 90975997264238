import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link as StyledLink } from './styled'

interface LinkProps {
  children: React.ReactNode
  className?: string
  href: string
  target?: string
  rel?: string
}

const Link = (props: LinkProps) => {
  const { children, href, target, rel, className } = props
  const location = useLocation()
  const pathName = location.pathname
  const pathArray = pathName.split('/')

  const isActive = pathName === href || href?.substring(1) === pathArray[1]

  return (
    <StyledLink
      className={className}
      href={href}
      rel={rel}
      isActive={isActive}
      target={target}
    >
      {children}
    </StyledLink>
  )
}

Link.defaultProps = {
  children: null,
  to: '',
}

export default Link
