import { createContext, ReactNode, useContext, useEffect, useRef } from 'react'
import useProducts, { UseProducts } from './useProducts'
import useProductsState from './useProductsState'
import { useProductsListContext } from '..'

interface ProductsProviderProps {
  children: ReactNode
}

const useProductsInitialState: UseProducts = {
  loading: false,
  error: '',
  products: {},
  fetchProducts: () => null,
}

export const ProductsContext = createContext<UseProducts>(
  useProductsInitialState
)

export const useProductsContext = () => useContext(ProductsContext)

export const APP_DEPENDENCY_NAME_PRODUCTS = 'products'

export const ProductsProvider = (props: ProductsProviderProps) => {
  const { children } = props
  const { products } = useProductsListContext()
  const state = useProductsState()
  const value = useProducts({ state })

  const stateActionsRef = useRef(state.actions)

  useEffect(() => {
    stateActionsRef.current = state.actions
  }, [state.actions])

  useEffect(() => {
    const { fetchProductsSuccess } = stateActionsRef.current
    fetchProductsSuccess(products)
  }, [products, state.actions.fetchProductsSuccess])

  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  )
}
