import Guides  from './views/Guides'
import { Routes, Route } from './entities/Routes'
import { useAppContext } from './entities/App/AppProvider'
import Home from './views/Home'
import GettingStarted from './views/GettingStarted'
import Documentation from './views/Documentation'
import SupportNew from './views/SupportNew'
import PPSAPILandingPage from './views/PPSAPILandingPage'
import LegacyGuideRoutesRedirects from './views/Guides/LegacyGuideRoutes'

const routes: Routes = {
  home: {
    name: 'home',
    path: `/`,
    component: <Home />,
    exact: true,
    title: 'Home',
  },
  gettingStarted: {
    name: 'gettingStarted',
    path: '/getting-started',
    component: <GettingStarted />,
    exact: true,
    title: 'Getting Started',
  },
  documentation: {
    name: 'documentation',
    path: '/documentation/:product?/:version?',
    component: <Documentation />,
    title: 'Documentation',
  },
  legacyAPIGuide: {
    name: 'legacy api guide',
    path: '/guides/*',
    component: <LegacyGuideRoutesRedirects />,
  },
  support: {
    name: 'support',
    path: '/support/:name?',
    title: 'Support',
    component: <SupportNew />,
  },
  landing: {
    name: 'landing',
    path: '/landing',
    title: 'Guides Home',
    component: <PPSAPILandingPage />,
  },
  guides: {
    name: 'guides',
    path: '/:vertical/guides/*',
    component: <Guides />,
    title: 'Guides',
  },
}

export const useActiveRoute = (): Route | undefined => {
  const { config } = useAppContext()
  const path = window.location.pathname || ''
  const rootPath = config?.ROOT_URL ? path.replace(config?.ROOT_URL, '') : ''

  if (!rootPath || rootPath === '/') {
    return routes.home
  }

  const currentPage = rootPath.split('/')[1]

  if (currentPage) {
    return Object.values(routes).find((route) => {
      return route.path?.includes(currentPage)
    })
  }
}

export default routes
