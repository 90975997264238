import { useState, useEffect, useCallback, useMemo } from 'react'
import {
  AppInitializedProps,
  AppInitialized,
  DependenciesInitialized,
} from './types'

const useAppInitialized = (props: AppInitializedProps): AppInitialized => {
  const { onInitialized, config } = props

  // Ensure that default state is false for config because otherwise initialized
  // will always start as true before it allows dependencies further in the tree to be registered.
  const [
    registeredDependencies,
    setRegisteredDependencies,
  ] = useState<DependenciesInitialized>({ config: false })

  const registerDependency = useCallback(
    (name: string) =>
      setRegisteredDependencies((prev: DependenciesInitialized) => ({
        ...prev,
        initial: true,
        [name]: false,
      })),
    [setRegisteredDependencies]
  )

  const setDependencyInitialized = useCallback(
    (name: string, initialized: boolean) =>
      setRegisteredDependencies((prev: DependenciesInitialized) => ({
        ...prev,
        [name]: initialized,
      })),
    [setRegisteredDependencies]
  )

  useEffect(() => {
    setRegisteredDependencies((prev) => ({
      ...prev,
      config: !!config,
    }))
  }, [config, setRegisteredDependencies])

  const initialized = useMemo(
    () =>
      !Object.values(registeredDependencies).some((dependency) => !dependency),
    [registeredDependencies]
  )

  useEffect(() => {
    if (onInitialized && initialized) {
      onInitialized(initialized)
    }
  }, [initialized, onInitialized])

  return {
    dependenciesInitialized: registeredDependencies,
    registerDependency,
    setDependencyInitialized,
    initialized,
  }
}

export default useAppInitialized
