import { useMemo } from 'react'
import styled from 'styled-components'
import { BackgroundImage, Heading, Flex, Box } from 'pcln-design-system'
import Section from './Section'
import CodeBlockBg from '../../assets/images/json-block-bg.png'
import * as Prism from 'prismjs'
import media from '../../media'
import DOMPurify from 'dompurify'

const Container = styled(Section)`
  min-height: 700px;
  ${media.tablet`
        min-height: auto;`}
`

const Background = styled(BackgroundImage)`
  background: url(${CodeBlockBg}) no-repeat center;
`

const Wrapper = styled(Flex)`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  align-items: center;

  ${media.tablet`
        flex-direction: column;`}
`

const Info = styled(Box)`
  text-align: left;
  padding: 0 40px 0 20px;

  ${media.tablet`
        width: 100%;`}
`

const Codes = styled(Box)`
  padding: 10px 20px;
  background: #031832;
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
  text-align: initial;
  overflow-x: auto;

  ${media.tablet`
        width: 95%;`}
`

const code = `{
    "getHotelAutoSuggestV2": {
        "results": {
            "status": "Success",
            "status_code": 100,
            "result": {
                "cities": [
                    {
                        "pet_count": 19,
                        "state_code": "MB",
                        "coordinate": "49.8951,-97.1384",
                        "state": "Manitoba",
                        "country_code": "CA",
                        "city": "Winnipeg",
                        "country": "Canada",
                        "rank": 73,
                        "cityid_ppn": "800036474",
                        "longitude": -97.1384,
                        "latitude": 49.8951,
                        "hotel_count": 73,
                        "type": "city",
                        "score": 0.12077854
                    }
                ]
            }
        }
    }
}`

const CodeBlock = () => {
  const codeBlock = useMemo(
    () => Prism.highlight(code, Prism.languages.javascript, 'js'),
    []
  )

  return (
    <Container>
      <Background height={'100%'}>
        <Wrapper>
          <Info width={1 / 2} align={'center'}>
            <Heading.h2>Easy to read response</Heading.h2>
            <p>
              Integrate dynamic hotel, rental car and flight data from multiple
              sources directly into your website or application.
            </p>
          </Info>
          <Codes width={1 / 2} align={'center'}>
            <pre>
              <div
                className="language-json"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(codeBlock),
                }}
              />
            </pre>
          </Codes>
        </Wrapper>
      </Background>
    </Container>
  )
}

CodeBlock.defaultProps = {
  code: '',
}

export default CodeBlock
