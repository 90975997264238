import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  Version,
  VersionsContainer,
  VersionSelectorContainer,
  VersionLabel,
  CurrentVersion,
} from './styled'
import { useCurrentProductContext } from '../../../entities/Guides'
import { useVersion } from '../mappers'

interface VersionSelectorProps {
  onDocumentClick: () => void
}

const VersionSelector = (props: VersionSelectorProps) => {
  const { onDocumentClick } = props
  const { currentProduct, currentVersion } = useCurrentProductContext()
  const versions: string[] = useMemo(
    () =>
      currentProduct && currentProduct.versions
        ? Object.keys(currentProduct.versions)
        : [],
    [currentProduct]
  )
  const renderedVersions = useMemo(
    () =>
      versions.map((version: string) => (
        <Link to={`/documentation/${currentProduct?.name}/${version}`}>
          <Version isActive={currentVersion === version}>{version}</Version>
        </Link>
      )),
    [versions, currentProduct, currentVersion]
  )

  useEffect(() => {
    document.addEventListener('click', onDocumentClick)

    return () => document.removeEventListener('click', onDocumentClick)
  }, [onDocumentClick])

  return (
    <VersionSelectorContainer>
      <Card p={2} boxShadowSize={'md'}>
        {renderedVersions}
      </Card>
    </VersionSelectorContainer>
  )
}

const Versions = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const close = useCallback(() => setIsOpen(false), [setIsOpen])
  const toggle = useCallback(() => setIsOpen((prev: boolean) => !prev), [
    setIsOpen,
  ])
  const version = useVersion()

  return (
    <VersionsContainer
      justifyContent={'flex-end'}
      alignItems={'center'}
      onClick={toggle}
    >
      <VersionLabel>Version:</VersionLabel>
      <CurrentVersion>{version}</CurrentVersion>
      {isOpen && <VersionSelector onDocumentClick={close} />}
    </VersionsContainer>
  )
}

export default Versions
