import React, { useMemo, ReactNode } from 'react'
import Prism from 'prismjs'
import { Container } from './styled'
import './prism.css'
import DOMPurify from 'dompurify'

interface CodeProps {
  className?: string
  children: ReactNode[]
}

const getPrismCode = (language: string, value: string): string | undefined => {
  switch (language) {
    case 'language-json':
    case 'language-js':
      return Prism.highlight(value, Prism.languages.javascript, 'js')
    default:
      return value
  }
}

const Code = (props: CodeProps) => {
  const { className: language } = props
  const children = String(props.children)

  const code = useMemo(() => {
    if (language && children) {
      return getPrismCode(language, children)
    }

    return children
  }, [language, children])

  return (
    <Container>
      <pre>
        {code ? (
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(code) }} />
        ) : (
          <React.Fragment />
        )}
      </pre>
    </Container>
  )
}

export default Code
