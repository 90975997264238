import { API } from '../../api/API'
import {
  guidesInitialState,
  GuidesState,
  UseGuidesState,
} from './useGuidesState'
import { GuideFilters, Guides } from './types'

export interface UseGuides extends GuidesState {
  fetchGuides: (filters: GuideFilters, vertical: string) => void
}

export const useGuidesInitialState: UseGuides = {
  ...guidesInitialState,
  fetchGuides: () => null,
}

export interface UseGuidesProps {
  API: API
  state: UseGuidesState
}

export const useGuides = (props: UseGuidesProps): UseGuides => {
  const {
    state: { state: guides, actions },
    API,
  } = props

  const fetchGuides = async (filters: GuideFilters, vertical: string) => {
    try {
      actions.fetchGuides()
      const { data } = await API.fetchGuides(filters, vertical)
      const fetchedGuides = data as Guides
      actions.fetchGuidesSuccess(fetchedGuides)
    } catch (exception) {
      console.error(exception)
      actions.fetchGuidesError()
    }
  }

  return {
    ...guides,
    fetchGuides,
  }
}

export default useGuides
