import React, { createContext, ReactNode, useContext } from 'react'
import useGuides, { UseGuides, useGuidesInitialState } from './useGuides'
import useGuidesState from './useGuidesState'
import { API } from '../../api/API'
export const GuidesContext = createContext<UseGuides>(useGuidesInitialState)

export const useGuidesContext = () => useContext(GuidesContext)

interface GuidesProviderProps {
  children: ReactNode
  api: API
}

export const GuidesProvider = (props: GuidesProviderProps) => {
  const { children, api } = props
  const state = useGuidesState()
  const value = useGuides({ API: api, state })

  return (
    <GuidesContext.Provider value={value}>{children}</GuidesContext.Provider>
  )
}

export default GuidesProvider
