import {
  createContext,
  useEffect,
  useContext,
  useCallback,
  useState,
} from 'react'
import { useAppContext } from '../App'
import { useOktaAuth } from '@okta/okta-react'
import { APP_DEPENDENCY_NAME_AUTH, useAuthContext } from './AuthProvider'
import { OktaUserProviderProps, OktaUser } from './OktaUser'
import { useUsersContext, User } from '../Users'

export const OktaUserContext = createContext<User | undefined>(undefined)

export const useOktaUserContext = () =>
  useContext<User | undefined>(OktaUserContext)

export const APP_DEPENDENCY_NAME_OKTA_USER = 'OKTA_USER'

const OktaUserProvider = (props: OktaUserProviderProps) => {
  const { children } = props
  const {
    registerDependency,
    setDependencyInitialized,
    dependenciesInitialized,
  } = useAppContext()
  const { authService } = useOktaAuth()
  const { isAuthorized } = useAuthContext()
  const { setCurrentUser, currentUser } = useUsersContext()

  const setUserFromOkta = useCallback(async () => {
    if (isAuthorized) {
      const user: OktaUser = await authService.getUser()

      const convertedUser = {
        firstName: user?.given_name,
        groups: user?.groups,
      }

      setCurrentUser(convertedUser)
    }

    setDependencyInitialized(APP_DEPENDENCY_NAME_OKTA_USER, true)
  }, [isAuthorized, authService, setDependencyInitialized, setCurrentUser])

  const [
    dependenciesInitializedAUTHVal,
    setDependenciesInitializedAUTHVal,
  ] = useState(dependenciesInitialized[APP_DEPENDENCY_NAME_AUTH])

  useEffect(() => {
    registerDependency(APP_DEPENDENCY_NAME_OKTA_USER)
  }, [registerDependency])

  useEffect(() => {
    setDependenciesInitializedAUTHVal(
      dependenciesInitialized[APP_DEPENDENCY_NAME_AUTH]
    )
  }, [dependenciesInitialized])

  useEffect(() => {
    if (dependenciesInitializedAUTHVal) {
      setUserFromOkta()
    }
  }, [dependenciesInitializedAUTHVal, setUserFromOkta])

  return (
    <OktaUserContext.Provider value={currentUser}>
      {children}
    </OktaUserContext.Provider>
  )
}

OktaUserProvider.defaultProps = {
  children: null,
}

export default OktaUserProvider
