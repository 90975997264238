import { Dispatch, SetStateAction, useState } from 'react'
import { UsersState, initialUsersState } from '../types'
import { useUsersActions, UsersActions } from '../useUsersActions'

export interface UseUsersState {
  state: UsersState
  actions: UsersActions
}

export interface UseUserActionsProps {
  state: UsersState
  setState: Dispatch<SetStateAction<UsersState>>
}

export const useUsersState = (): UseUsersState => {
  const [state, setState] = useState<UsersState>(initialUsersState)
  const actions = useUsersActions({ state, setState })

  return {
    state,
    actions,
  }
}

export default useUsersState
