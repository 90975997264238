import React, { ReactNode } from 'react'
import HeroCard from '../../HeroCard'
import { Container, ContentContainer } from './styled'

interface ContentProps {
  children: ReactNode
  loading?: boolean
  heroImage?: string
  title?: string
  subtitle?: string
}

const Content = (props: ContentProps) => {
  const { children, loading, heroImage, title, subtitle } = props

  return (
    <Container>
      <ContentContainer $loading={loading || false}>
        <HeroCard
          heroImage={heroImage}
          title={title}
          subtitle={subtitle}
          loading={loading}
        />
        {children}
      </ContentContainer>
    </Container>
  )
}

export default Content
