import { useMemo } from 'react'
import axios from 'axios'
import { useAppContext } from '../../entities/App'
import { useAuthContext } from '../../entities/Auth'
import { APIRequest, RequestConfig } from './types'

const initialConfig: RequestConfig = {
  auth: true,
  onInit: undefined,
  headers: undefined,
}

export const useAPIRequest = (
  requestConfig: RequestConfig = initialConfig
): APIRequest => {
  const { config } = useAppContext()
  const { token } = useAuthContext()

  return useMemo(() => {
    const request = axios.create({
      baseURL: config?.BASE_API_URL,
      headers: token
        ? {
            ...requestConfig.headers,
            Authorization: `Bearer ${token}`,
          }
        : requestConfig.headers,
    })

    return request
  }, [config, token, requestConfig.headers])
}
