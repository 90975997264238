import React from 'react'
import { Container } from './styled'

interface SpinnerProps {
  size: number
}

const Spinner = (props: SpinnerProps) => {
  const { size } = props

  return (
    <Container size={size}>
      <div />
      <div />
      <div />
      <div />
    </Container>
  )
}

Spinner.defaultProps = {
  size: 1,
}

export default Spinner
